export const darkTheme = {
  palette: {
    mode: "dark",
    primary: {
      main: "#2196F3",
    },
    secondary: {
      main: "#21CBF3",
    },
    background: {
      default: "#303030",
      paper: "#424242",
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeightLight: 400,
    fontSize: 14,
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 110,
          overflow: "hidden",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "gradient" },
          style: {
            background: "linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)",
            border: 0,
            boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
            color: "white",
            height: 40,
            padding: "0 20px",
          },
        },
      ],
    },
    MuiSwitch: {
      variants: [
        {
          props: { variant: "big" },
          root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: 8,
          },
          switchBase: {
            padding: 1,
            "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
              transform: "translateX(16px)",
              color: "#fff",
              "& + $track": {
                opacity: 1,
                border: "none",
              },
            },
          },
          thumb: {
            width: 24,
            height: 24,
          },
          track: {
            borderRadius: 13,
            border: "1px solid #bdbdbd",
            backgroundColor: "#fafafa",
            opacity: 1,
            transition:
              "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          },
        },
      ],
    },
  },
  shape: {
    borderRadius: 8,
  },
};

export const lightTheme = {
  palette: {
    mode: "light",
    primary: {
      main: "#2196F3",
    },
    secondary: {
      main: "#21CBF3",
    },
    background: {
      default: "#E7EBF0",
      paper: "#fff",
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeightLight: 400,
    fontSize: 14,
  },
  props: {
    MuiList: {
      dense: false,
    },
    MuiMenuItem: {
      dense: false,
    },
    MuiTable: {
      size: "small",
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 110,
          overflow: "hidden",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "gradient" },
          style: {
            background: "linear-gradient(45deg, #21CBF3 30%, #2196F3 90%)",
            border: 0,
            boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
            color: "white",
            height: 40,
            padding: "0 20px",
          },
        },
      ],
    },
    MuiSwitch: {
      variants: [
        {
          props: { variant: "big" },
          root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: 8,
          },
          switchBase: {
            padding: 1,
            "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
              transform: "translateX(16px)",
              color: "#fff",
              "& + $track": {
                opacity: 1,
                border: "none",
              },
            },
          },
          thumb: {
            width: 24,
            height: 24,
          },
          track: {
            borderRadius: 13,
            border: "1px solid #bdbdbd",
            backgroundColor: "#fafafa",
            opacity: 1,
            transition:
              "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          },
        },
      ],
    },
  },
  shape: {
    borderRadius: 8,
  },
};
