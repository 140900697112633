/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Box,
  Button,
  DialogActions,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";

import { useModal } from "../../../core/store/modal";
import { getDataString } from "../../../core/helpers/utils";
import { ArrowBack, Edit, StyleOutlined } from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { useVivysub } from "../../../core/helpers/vivysub";
import { useWindowSize } from "../../../core/hooks/useWindowSize";

function StylesDialog() {
  const modal = useModal();
  const { width } = useWindowSize();
  const theme = useTheme();
  const { t } = useTranslation();
  const { version, vivysub } = useVivysub();
  const styles = useMemo(() => {
    return vivysub.getStyles({ onlyStyles: true });
  }, [version]);

  const handleClose = () => {
    modal.change("styles", false);
  };

  const isMobile = width < theme.breakpoints.values.md;

  return (
    <div>
      <Dialog
        open={modal["styles"]}
        onClose={handleClose}
        fullScreen={isMobile}
      >
        <DialogTitle
          sx={{ mt: window.electron.isMac && isMobile ? 2 : undefined }}
        >
          {isMobile && (
            <IconButton onClick={handleClose} sx={{ mr: 1, ml: -1 }}>
              <ArrowBack />
            </IconButton>
          )}
          {t("modals:styles.title")}
        </DialogTitle>

        <List sx={{ p: 0, width: isMobile ? "100%" : 500, height: "100%" }}>
          {styles.map((style: any) => {
            const { Fontname, Fontsize, Name } = style;
            return (
              <ListItem button>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <StyleOutlined />
                </ListItemIcon>
                <ListItemText
                  primary={Name}
                  secondary={getDataString([Fontname, `${Fontsize} памер`])}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => {
                      handleClose();
                      modal.change("details.style", true);
                      modal.change("props", style);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
          {!styles.length && (
            <Box
              sx={{
                p: 2,
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                py: 6,
                height: "100%",
                minHeight: "100%",
              }}
            >
              <StyleOutlined sx={{ fontSize: "4rem" }} />
              <Typography variant="h6" sx={{ mt: 1 }}>
                Няма стыляў
              </Typography>
            </Box>
          )}
        </List>

        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button
            variant={"gradient" as any}
            onClick={() => {
              handleClose();
            }}
            fullWidth={isMobile}
          >
            {t("modals:styles.button")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(StylesDialog);
