/* eslint-disable react-hooks/exhaustive-deps */
/* tslint:disable */
import React, { useEffect, useMemo, useRef, useState } from "react";
import SubtitlesOctopus from "libass-wasm";
import fonts from "google-fonts-complete";
import { Box, Divider, IconButton, Slider, ListItemText } from "@mui/material";
import { msSubtitle, timeToSeconds } from "../../../core/helpers/utils";
import humanizeDuration from "humanize-duration";
import { isEmpty, uniqBy } from "lodash";
import { useVivysub } from "../../../core/helpers/vivysub";

import { subtitlesSelectors } from "../../../core/store/subtitles/selectors";
import { useVideo } from "../../../core/store/video";
import {
  FullscreenOutlined,
  PauseOutlined,
  PlayArrowOutlined,
} from "@mui/icons-material";

let instences: typeof SubtitlesOctopus[] = [];

interface IVideoPlayer {}

const Controls = ({ videoInstance }: any) => {
  const [state, setState] = useState<number>(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setState(state + 1);
    }, 25);

    return () => clearTimeout(timer);
  }, [state]);

  const currentTime = useMemo(() => {
    return videoInstance?.currentTime || 0;
  }, [state]);

  const duration = useMemo(() => {
    return videoInstance?.duration || 100;
  }, [state]);

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          bottom: 30,
          mb: -2.46,
          left: 10,
          display: "flex",
          alignItems: "center",
          zIndex: 999,
          right: 10,
        }}
      >
        <IconButton
          sx={{ color: "primary.main" }}
          onClick={() => {
            if (videoInstance.paused) {
              videoInstance.play();
            } else {
              videoInstance.pause();
            }
          }}
        >
          {!videoInstance.paused ? <PauseOutlined /> : <PlayArrowOutlined />}
        </IconButton>
        <Divider
          orientation="vertical"
          sx={{ mx: 1, height: 30, borderColor: "primary.light" }}
        />
        <Box sx={{ ml: 1, width: "100%", display: "flex" }}>
          <ListItemText
            primary={msSubtitle(videoInstance.currentTime * 1000)}
            secondary={humanizeDuration(videoInstance.duration, {
              units: ["m"],
              round: true,
              unitMeasures: {
                m: 60,
              },
            })}
            secondaryTypographyProps={{ color: "white", fontSize: "0.8rem" }}
            primaryTypographyProps={{ color: "white", fontSize: "0.6rem" }}
          />
        </Box>
        <Box
          sx={{ justifyContent: "flex-end", width: "100%", display: "flex" }}
        >
          <IconButton
            sx={{ color: "primary.main" }}
            onClick={() => {
              if (videoInstance.requestFullscreen) {
                videoInstance.requestFullscreen();
              } else if (videoInstance.mozRequestFullScreen) {
                videoInstance.mozRequestFullScreen();
              } else if (videoInstance.webkitRequestFullscreen) {
                videoInstance.webkitRequestFullscreen();
              } else if (videoInstance.msRequestFullscreen) {
                videoInstance.msRequestFullscreen();
              }
            }}
          >
            <FullscreenOutlined />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          mb: -2.46,
          left: 0,
          right: 0,
          zIndex: 999,
        }}
      >
        <Slider
          sx={{ borderRadius: 0 }}
          max={duration}
          value={currentTime}
          onChange={(_, value: number) => {
            videoInstance.currentTime = +value;
          }}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => {
            return humanizeDuration(value, {
              units: ["m", "s"],
              round: true,
              unitMeasures: {
                m: 60,
                s: 1,
              },
            });
          }}
        />
      </Box>
    </>
  );
};

const VideoPlayer = (props: IVideoPlayer) => {
  const { meta } = useVideo();
  const { currentSubtitleId } = subtitlesSelectors.getMeta();

  const { vivysub, version } = useVivysub();
  const uniqFonts = useMemo<any>(() => {
    const styles = vivysub.getStyles({ onlyStyles: true });

    return uniqBy(styles, "Fontname");
  }, [version]);

  const styles = useMemo<any>(() => {
    return vivysub.getStyles({ onlyStyles: true });
  }, []);

  const translated = useMemo<any>(() => {
    return vivysub.export();
  }, [version]);

  const currentSubtitle = useMemo<any>(() => {
    const dialogs = vivysub.getFilteredEvents({ onlyDialogs: true });

    return dialogs.find((dialog: any) => dialog.id === currentSubtitleId);
  }, [version, currentSubtitleId]);

  const fontsSrc = useMemo(() => {
    const result = [
      "http://link.eu1.storjshare.io/jwtavenjehavhz34o55fatu4bpyq/anibel%2FFonts%2FMontserrat-Bold.ttf?download",
    ];

    for (const { Fontname } of uniqFonts) {
      const [name] =
        Fontname?.split("Bold")?.map((d: string) => d.trim()) || [];
      const font = fonts[name];

      if (font) {
        for (const variant of Object.entries(font.variants)) {
          const value = variant[1];

          for (const details of Object.entries(value as any)) {
            const {
              url: { ttf },
            } = details[1] as any;

            result.push(ttf.replace("https", "http"));
          }
        }
      }
    }

    return result;
  }, [styles]);

  const videoRef = useRef<HTMLVideoElement>({} as HTMLVideoElement);

  const octopusInstance = useMemo(() => {
    if (!meta.filePath || isEmpty(videoRef.current) || !translated) {
      return null;
    }

    const instance = new SubtitlesOctopus({
      fonts: fontsSrc,
      subContent: translated,
      video: videoRef.current,
      lossyRender: false,
    });

    for (const iterator of instences) {
      try {
        iterator?.dispose();
      } catch (error) {}
    }

    instences = [];
    instences.push(instance);

    return instance;
  }, [meta.filePath, videoRef.current]);

  useEffect(() => {
    octopusInstance?.setTrack(translated);
  }, [translated, octopusInstance]);

  useEffect(() => {
    if (videoRef.current && currentSubtitle) {
      const seconds = timeToSeconds(currentSubtitle.Start);
      videoRef.current.currentTime = Number(seconds);

      if (octopusInstance) {
        octopusInstance.setCurrentTime(Number(seconds));
      }
    }
  }, [currentSubtitle, octopusInstance, videoRef.current]);

  useEffect(() => {
    videoRef.current?.load();
  }, [meta.filePath]);

  if (!meta.filePath) {
    return null;
  }

  return (
    <div>
      <Box
        sx={{
          overflow: "hidden",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          background: "black",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            display: "block",
            position: "relative",
            maxHeight: 360,
          }}
        >
          <video
            ref={videoRef}
            controls={false}
            style={{
              maxHeight: 360,
              height: "100%",
              objectFit: "cover",
              width: "100%",
            }}
          >
            <source src={meta.filePath} type="video/mp4" />
          </video>
        </Box>
        {videoRef.current && <Controls videoInstance={videoRef.current} />}
      </Box>
    </div>
  );
};

export default VideoPlayer;
