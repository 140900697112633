import { FixedSizeList } from "react-window";
import List from "@mui/material/List";
import { Box } from "@mui/system";
import React from "react";

import AutoSizer from "react-virtualized-auto-sizer";
import { useResizeDetector } from "react-resize-detector";
import { DrawerEditor } from "../../components/editor/DrawerEditor";
import { RenderRow } from "../../components/editor/RenderRow";

import { withWrapper } from "../../../core/helpers/wrapper";
import { useEditorWrapper } from "./wrapper";
import { IUISettings } from "../../../core/store/settings";
import { IVideoReducer } from "../../../core/store/video";
import { EntityDialogValue, Subtitles } from "../../../core/types/subtitles";
import { SubtitlesOffOutlined } from "@mui/icons-material";
import { Typography, Button } from "@mui/material";
import ActionMenu from "../../components/editor/ActionMenu";
import VideoPlayer from "../../components/editor/VideoPlayer";
import { hasElectron } from "../../../core/helpers/polyfill";

interface IEditorPage {
  ui: IUISettings;
  drawerWidth: number;
  vid: IVideoReducer;
  filtered: EntityDialogValue[];
  original: Subtitles;
  handleOpenSubtitle: () => void;
  createSubtitle: () => void;
  scrollRef: any;
  currentSubtitle?: EntityDialogValue;
}

const EditorPage = ({
  ui,
  drawerWidth,
  vid,
  filtered,
  original,
  handleOpenSubtitle,
  createSubtitle,
  currentSubtitle,
  scrollRef,
}: IEditorPage) => {
  const { height, ref } = useResizeDetector();

  const hasVideo = vid?.meta?.filePath;

  const style: any = {
    maxHeight: "100%",
    height: "100%",
    maxWidth:
      ui?.editDrawer && currentSubtitle
        ? `calc(100% - ${drawerWidth}px)`
        : undefined,
    width:
      ui?.editDrawer && currentSubtitle
        ? `calc(100% - ${drawerWidth}px)`
        : undefined,
    position: "relative",
  };

  if (hasVideo) {
    style.pt = `${height}px`;
  }

  return (
    <>
      <Box
        sx={
          {
            position: "relative",
            maxHeight: hasElectron
              ? "calc(100vh - 79px)"
              : "calc(100vh - 53px)",
            height: hasElectron ? "calc(100vh - 79px)" : "calc(100vh - 53px)",
            paddingBottom:
              "calc(env(safe-area-inset-bottom) + env(safe-area-inset-top))",
            backgroundColor: ui.mode === "light" && "white",
          } as any
        }
      >
        <Box
          ref={ref}
          sx={{
            width:
              ui?.editDrawer && currentSubtitle
                ? `calc(100% - ${drawerWidth}px)`
                : "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 120,
          }}
        >
          {hasVideo && <VideoPlayer />}
        </Box>
        <Box sx={style}>
          {!filtered.length ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                paddingTop: hasVideo ? `${height}px` : "0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <SubtitlesOffOutlined sx={{ fontSize: "4rem" }} />
              <Typography component={"span"} variant="h6" sx={{ mt: 1 }}>
                Няма субцітраў
              </Typography>
              <Button sx={{ textTransform: "none" }} onClick={createSubtitle}>
                Стварыць пусты субцітар
              </Button>
              <Button
                sx={{ textTransform: "none" }}
                onClick={handleOpenSubtitle}
              >
                Імпарціраваць з файла або відэа
              </Button>
            </Box>
          ) : (
            <List
              dence
              sx={{
                height: "100%",
                overflow: "hidden",
                overflowY: "auto",
                p: 0,
              }}
            >
              <AutoSizer>
                {({ height, width }: any) => (
                  <FixedSizeList
                    height={height}
                    width={width}
                    itemSize={72}
                    itemCount={filtered.length}
                    overscanCount={26}
                    ref={scrollRef}
                  >
                    {RenderRow}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </List>
          )}
          <ActionMenu />
        </Box>
        <DrawerEditor />
      </Box>
    </>
  );
};

export default withWrapper(EditorPage, useEditorWrapper);
