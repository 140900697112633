import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Button,
  DialogActions,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useModal } from "../../../core/store/modal";

import { Box } from "@mui/system";

import { useTranslation } from "react-i18next";
import {
  Apple,
  ArrowBack,
  EmailOutlined,
  GitHub,
  Google,
  LockOutlined,
  LoginOutlined,
  Twitter,
} from "@mui/icons-material";
import { useWindowSize } from "../../../core/hooks/useWindowSize";

function AuthorizationDialog() {
  const modal = useModal();
  const { width } = useWindowSize();
  const { t } = useTranslation();

  const handleClose = () => {
    modal.change("authorization", false);
  };

  const handleSave = () => {};

  const handleGoogleProvider = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;

        const user = result.user;

        console.log(token, user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        const email = error.customData.email;

        const credential = GoogleAuthProvider.credentialFromError(error);

        console.log(errorCode, errorMessage, email, credential);
      });
  };

  const isMobile = width < 460;

  return (
    <div>
      <Dialog
        open={modal?.authorization}
        onClose={handleClose}
        fullScreen={isMobile}
      >
        <DialogTitle
          sx={{
            alignItems: "center",
            display: "flex",
            mt: window.electron.isMac && isMobile ? 2 : undefined,
          }}
        >
          {isMobile && (
            <IconButton onClick={handleClose} sx={{ mr: 1, ml: -1 }}>
              <ArrowBack />
            </IconButton>
          )}
          {t("modals:authorization.title")}
        </DialogTitle>
        <Box
          sx={{
            justifyContent: "center",
            width: "100%",
            flex: 1,
            display: "flex",
            mb: 2,
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <IconButton
            sx={{ mx: 1, maxHeight: "50px" }}
            onClick={handleGoogleProvider}
          >
            <Google fontSize="large" />
          </IconButton>
          <IconButton
            sx={{
              mx: 1,
              alignItems: "center",
              display: "flex",
              maxHeight: "50px",
            }}
          >
            <Twitter fontSize="large" />
          </IconButton>
          <IconButton
            sx={{
              mx: 1,
              alignItems: "center",
              display: "flex",
              maxHeight: "50px",
            }}
          >
            <Apple fontSize="large" sx={{ mt: -0.5 }} />
          </IconButton>

          <IconButton
            sx={{
              mx: 1,
              alignItems: "center",
              display: "flex",
              maxHeight: "50px",
            }}
          >
            <GitHub fontSize="large" />
          </IconButton>
        </Box>

        <Divider>{t("modals:authorization.or")}</Divider>
        <DialogContent
          sx={{
            maxWidth: 560,
            width: width >= 600 ? 600 : isMobile ? "100%" : 300,
            px: 2,
            alignItems: "center",
            display: "flex",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <TextField
              fullWidth
              label={t("modals:authorization.email")}
              placeholder={t("modals:authorization.email_placeholder")}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ my: 3 }} />
            <TextField
              fullWidth
              type="password"
              label={t("modals:authorization.password")}
              placeholder={t("modals:authorization.password_placeholder")}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions
          sx={{
            p: 2,
            justifyContent: "space-between",
            flexDirection: width >= 640 ? "row" : "column-reverse",
          }}
        >
          <Button fullWidth={width < 640} sx={{ mt: width < 640 ? 2 : 0 }}>
            {t("modals:authorization.recovery")}
          </Button>
          <Button
            variant={"gradient" as any}
            onClick={handleSave}
            fullWidth={width < 640}
            endIcon={<LoginOutlined />}
          >
            {t("modals:authorization.login")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AuthorizationDialog;
