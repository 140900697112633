const defaultMapper = (options: any) => {
  const result = {} as any;

  for (const option of options) {
    result[option] = option;
  }

  return result;
};

export const createAction = (type: any, mapper = defaultMapper) => {
  return (...args: any) => ({
    type: typeof args[0] === "function" ? type : type + "/" + args[0],
    payload:
      typeof args[0] === "function"
        ? { key: null, value: args[0] }
        : mapper(args),
  });
};
