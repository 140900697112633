import { ArrowForward, TimelapseOutlined } from "@mui/icons-material";
import { Chip, Grid, Popover, TextField, Tooltip } from "@mui/material";
import React, { useMemo, useState } from "react";
import { translateSubtitlesKey } from "../../../core/helpers/utils";
import { useVivysub } from "../../../core/helpers/vivysub";
import { useSubtitles } from "../../../core/store/subtitles";

interface ITimingChip {
  start: string;
  end: string;
  id: string;
}

const TimingChip = ({ start, end, id }: ITimingChip) => {
  const { change } = useSubtitles();
  const { vivysub, version } = useVivysub();
  const translated = useMemo(() => {
    return vivysub.getFilteredEvents({ onlyDialogs: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  const [state, setState] = useState({
    Start: start,
    End: end,
    error: {
      Start: false,
      End: false,
    },
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  const handleChange =
    (type: any) =>
    ({ target: { value } }: any) => {
      const reg = /^\d:\d\d:\d\d\.\d\d/gs;

      setState({
        ...state,
        [type]: value,
        error: {
          ...state.error,
          [type]: !reg.test(value),
        },
      });

      if (reg.test(value)) {
        const newTranslated = translateSubtitlesKey(
          id,
          type,
          value as any,
          translated
        );

        change("translated", newTranslated);
      }
    };

  return (
    <>
      <Chip
        icon={<TimelapseOutlined />}
        sx={{ lineHeight: "24px", cursor: "pointer" }}
        label={
          <Tooltip title="Націсніце, каб змяніць таймінг">
            <div onClick={handleClick}>
              <span
                style={{
                  minHeight: 24,
                  maxHeight: 24,
                  height: 24,
                  lineHeight: "22.5px",
                }}
              >
                {start} {"->"} {end}
              </span>
            </div>
          </Tooltip>
        }
        size="small"
      />
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorPosition={{
          left: 0,
          top: 0,
        }}
        sx={{ mt: 1.5, ml: -3.2 }}
      >
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item>
            <TextField
              fullWidth
              value={state.Start}
              onChange={handleChange("Start")}
              placeholder="0:00:00.00"
              InputProps={{
                startAdornment: <TimelapseOutlined sx={{ mr: 1 }} />,
              }}
              error={state.error.Start}
              size="small"
            />
          </Grid>
          <Grid
            item
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              maxWidth: 20,
            }}
          >
            <ArrowForward />
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              value={state.End}
              onChange={handleChange("End")}
              placeholder="0:00:00.00"
              InputProps={{
                startAdornment: <TimelapseOutlined sx={{ mr: 1 }} />,
              }}
              error={state.error.End}
              size="small"
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default React.memo(TimingChip);
