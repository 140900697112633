import { createAction } from "../../helpers/createAction";
import { createSelectorHook } from "../../helpers/createSelectorHook";
import { createActionHook } from "../../helpers/createActionHook";
import { RootState } from "../rootReducer";
import set from "lodash/set";
import clone from "lodash/clone";

const ID = "MODAL";

const changeAction = createAction(ID, ([key, value]) => ({ key, value }));
const changeHook = createActionHook(changeAction);
const getModal = createSelectorHook<RootState["modal"]>(
  () => (state: RootState) => state.modal
);

const initialState = {};

export interface IModalReducer {
  confirm: boolean;
  about: boolean;
  settings: boolean;
  export: boolean;
  fonts: boolean;
  properties: boolean;
  styles: boolean;
  search: boolean;
  render: boolean;
  searchAndReplace: boolean;
  openVideoStream: boolean;
  selectTrack: boolean;
  authorization: boolean;
  edit: {
    timing: boolean;
    style: boolean;
    actor: boolean;
    comment: boolean;
  };
  details: {
    style: boolean;
  };
  add: {
    style: boolean;
  };
  props: any;
}

export const modalReducer = (
  state: typeof initialState = initialState,
  action: any
) => {
  if (action.type.includes(ID)) {
    const { key, value } = action.payload;

    if (!key && typeof value === "function") {
      return value(state);
    }

    return clone(set(clone(state), key, value));
  } else {
    return state;
  }
};

export interface IUseModal extends IModalReducer {
  change: (key: string | any, value?: any) => void;
}

export const useModal = (): IUseModal => {
  const [change] = changeHook();
  const modal = getModal();

  return {
    ...modal,
    change,
  };
};
