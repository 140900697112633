import { Container, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { withWrapper } from "../../../core/helpers/wrapper";
import { ISubtitlesHistory } from "../../../core/store/subtitles";
import { useMainWrapper } from "./wrapper";

import { useWindowSize } from "../../../core/hooks/useWindowSize";
import MobileNavigation from "./mobileMenu";
import { hasElectron } from "../../../core/helpers/polyfill";
import { UserMenu } from "./UserMenu";
import Recently from "./Recently";
import Projects from "./Projects";
import Cloud from "./Cloud";
import Account from "./Account";

interface IMainPage {
  history: ISubtitlesHistory[];
  openHistoryFile: (path: string) => void;
  handleUpload: () => void;
  t: (key: string) => string;
  currentTab: number;
  setCurrentTab: (value: number) => void;
}

const Tabs = {
  0: <Recently />,
  1: <Projects />,
  2: <Cloud />,
  3: <Account />,
} as {
  [key: number]: any;
};

const MainPage = ({ t, currentTab, setCurrentTab }: IMainPage) => {
  const theme = useTheme();
  const { width } = useWindowSize();
  const isDesktop = width > theme.breakpoints.values.md;

  return (
    <Box>
      {isDesktop && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <UserMenu onChange={setCurrentTab} currentTab={currentTab} />
          <Box sx={{ m: 4, width: "100%" }}>{Tabs[currentTab]}</Box>
        </Box>
      )}
      {!isDesktop && (
        <Container
          sx={{
            flex: 1,
            minHeight: hasElectron
              ? "calc(100vh - 145px)"
              : "calc(100vh - 53px)",
            paddingBottom:
              "calc(env(safe-area-inset-bottom) + env(safe-area-inset-top))",
          }}
        >
          {Tabs[currentTab]}
        </Container>
      )}
      {!isDesktop && (
        <MobileNavigation onChange={setCurrentTab} currentTab={currentTab} />
      )}
    </Box>
  );
};

export default withWrapper(MainPage, useMainWrapper);
