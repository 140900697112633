import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Button,
  DialogActions,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";

import { useModal } from "../../../core/store/modal";
import { EntityStyleValue } from "../../../core/types/subtitles";
import { useTranslation } from "react-i18next";
import { useVivysub } from "../../../core/helpers/vivysub";
import { useDevice } from "../../../core/hooks/useDevice";
import { ArrowBack } from "@mui/icons-material";

function StylesDetailsDialog() {
  const modal = useModal();
  const { vivysub } = useVivysub();
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const style = modal.props as EntityStyleValue;
  const [state, setState] = useState<EntityStyleValue>(style);

  const handleClose = () => {
    modal.change("details.style", false);
    modal.change("props", {});
  };

  const handleSave = () => {
    vivysub.changeStyles(state.id, state);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={modal.details.style}
        onClose={handleClose}
        fullScreen={isMobile}
      >
        <DialogTitle
          sx={{ mt: window.electron.isMac && isMobile ? 2 : undefined }}
        >
          {isMobile && (
            <IconButton onClick={handleClose} sx={{ mr: 1, ml: -1 }}>
              <ArrowBack />
            </IconButton>
          )}
          {t("modals:style_details.title")}
        </DialogTitle>
        <DialogContent sx={{ minWidth: isMobile ? "100%" : 460 }}>
          {Object.keys(style)
            .filter((key) => !["key", "id"].includes(key))
            .map((key) => {
              const value = (style as any)[key];
              return (
                <TextField
                  size="small"
                  defaultValue={value}
                  value={value[key]}
                  onChange={({ target: { value: newKeyVakue } }) => {
                    setState({
                      ...state,
                      [key]: newKeyVakue,
                    });
                  }}
                  label={key}
                  key={key}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              );
            })}
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose} fullWidth={isMobile}>
            {t("ui:common.cancel")}
          </Button>
          <Button
            variant={"gradient" as any}
            fullWidth={isMobile}
            onClick={handleSave}
          >
            {t("ui:common.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(StylesDetailsDialog);
