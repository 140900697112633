import {
  EditOutlined,
  InfoOutlined,
  InsertDriveFileOutlined,
  SettingsOutlined,
  WindowOutlined,
  FileOpenOutlined,
  CloseOutlined,
  ArticleOutlined,
  Save,
  Subtitles,
  ArrowBack,
  ArrowForward,
  ContentCutOutlined,
  ContentCopyOutlined,
  FindReplaceRounded,
  SearchOutlined,
  ContentPasteGoOutlined,
  BookOutlined,
  Web,
  BugReport,
  OndemandVideoOutlined,
  VideoFileOutlined,
  VideoSettingsOutlined,
  DeleteOutlined,
  StyleOutlined,
  SaveAs,
  FontDownloadOutlined,
  ClosedCaptionOutlined,
  VideoLibraryOutlined,
} from "@mui/icons-material";
import SettingsInputAntennaOutlinedIcon from "@mui/icons-material/SettingsInputAntennaOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import React from "react";
import { hasElectron } from "../../core/helpers/polyfill";

import { IOSSwitch } from "./IOSSwitch";

import {
  handleCreateSubtitle,
  handleDictionaries,
  handleExist,
  handleFindAndReplace,
  handleFonts,
  handleFullscreen,
  handleGlossaries,
  handleHightlight,
  handleOpenSubtitle,
  handleProperties,
  handleRecenlty,
  handleRender,
  handleSaveAs,
  handleSaveSubtitle,
  handleSearch,
  handleSpellchecker,
  handleStyles,
  handleTags,
  handleTheme,
  handleVideoImport,
  handleVideoOpen,
  handleVideoRemove,
  handleVideoStream,
} from "./menubarHandlers";

const { shell } = window.electron;

export const options = [
  {
    name: "ui:file.title",
    icon: InsertDriveFileOutlined,
    key: "file",
    submenu: [
      {
        name: "ui:file.create",
        icon: InsertDriveFileOutlined,
        onClick: handleCreateSubtitle,
        hideOnClick: true,
      },
      {
        name: "ui:file.open",
        icon: FileOpenOutlined,
        divider: hasElectron,
        onClick: handleOpenSubtitle,
        hideOnClick: true,
      },
      {
        name: "ui:file.save",
        icon: Save,
        hidden: true,
        onClick: handleSaveSubtitle,
        hideOnClick: true,
      },
      {
        name: "ui:file.save_as",
        icon: SaveAs,
        divider: true,
        hidden: true,
        onClick: handleSaveAs,
        hideOnClick: true,
      },
      {
        name: "ui:file.recently",
        icon: Subtitles,
        divider: true,
        hidden: true,
        onClick: handleRecenlty,
        hideOnClick: true,
      },
      {
        name: "ui:file.properties",
        icon: ArticleOutlined,
        hidden: true,
        onClick: handleProperties,
        hideOnClick: true,
      },
      {
        name: "ui:file.fonts",
        icon: FontDownloadOutlined,
        hidden: true,
        onClick: handleFonts,
        hideOnClick: true,
      },
      {
        name: "ui:file.styles",
        icon: StyleOutlined,
        hidden: true,
        divider: hasElectron,
        onClick: handleStyles,
        hideOnClick: true,
      },
      hasElectron && {
        name: "ui:file.exit",
        icon: CloseOutlined,
        onClick: handleExist,
        danger: true,
      },
    ].filter(Boolean),
  },
  {
    name: "ui:edit.title",
    icon: EditOutlined,
    key: "edit",
    submenu: [
      {
        name: "ui:edit.undo",
        icon: ArrowBack,
        onClick:
          ({ vivysub }: any) =>
          () =>
            vivysub.undo(),
      },
      {
        name: "ui:edit.redo",
        icon: ArrowForward,
        divider: true,
        onClick:
          ({ vivysub }: any) =>
          () =>
            vivysub.redo(),
      },
      {
        name: "ui:edit.cut",
        icon: ContentCutOutlined,
      },
      {
        name: "ui:edit.copy",
        icon: ContentCopyOutlined,
      },
      {
        name: "ui:edit.paste",
        icon: ContentPasteGoOutlined,
        divider: true,
      },
      {
        name: "ui:edit.search",
        icon: SearchOutlined,
        onClick: handleSearch,
        hideOnClick: true,
      },
      {
        name: "ui:edit.find_and_replace",
        icon: FindReplaceRounded,
        onClick: handleFindAndReplace,
        hideOnClick: true,
      },
    ],
  },
  {
    name: "ui:video.title",
    icon: OndemandVideoOutlined,
    key: "video",
    submenu: [
      {
        name: "ui:video.add",
        icon: VideoLibraryOutlined,
        onClick: handleVideoOpen,
      },
      {
        name: "ui:video.stream",
        icon: SettingsInputAntennaOutlinedIcon,
        onClick: handleVideoStream,
      },
      {
        name: "ui:video.import",
        icon: ClosedCaptionOutlined,
        divider: true,
        onClick: handleVideoImport,
      },
      {
        name: "ui:video.settings",
        icon: VideoSettingsOutlined,
      },
      {
        name: "ui:video.render",
        icon: VideoFileOutlined,
        divider: true,
        onClick: handleRender,
      },

      {
        name: "ui:video.remove",
        icon: DeleteOutlined,
        onClick: handleVideoRemove,
        hideOnClick: true,
      },
    ],
  },
  {
    name: "ui:view.title",
    icon: WindowOutlined,
    key: "view",
    submenu: [
      {
        name: "ui:view.theme",
        disableClose: true,
        onClick: handleTheme,
        end: ({ ui }: any) => (
          <IOSSwitch sx={{ ml: 1, mr: 0 }} checked={ui?.mode === "dark"} />
        ),
        divider: true,
      },
      {
        name: "ui:view.tags",
        disableClose: true,
        onClick: handleTags,
        end: ({ ui }: any) => (
          <IOSSwitch
            sx={{ ml: 2, mr: 0 }}
            defaultChecked
            checked={ui?.tagInList}
          />
        ),
        divider: true,
      },
      {
        name: "ui:view.spellchecker",
        disableClose: true,
        onClick: handleSpellchecker,
        end: ({ ui }: any) => (
          <IOSSwitch
            sx={{ ml: 2, mr: 0 }}
            defaultChecked
            checked={ui?.spellchecker}
          />
        ),
      },
      {
        name: "ui:view.dictionaries",
        disableClose: true,
        onClick: handleDictionaries,
        end: ({ ui }: any) => (
          <IOSSwitch
            sx={{ ml: 2, mr: 0 }}
            defaultChecked
            checked={ui?.dictionaries}
          />
        ),
      },
      {
        name: "ui:view.glossaries",
        disableClose: true,
        onClick: handleGlossaries,
        end: ({ ui }: any) => (
          <IOSSwitch sx={{ ml: 2, mr: 0 }} defaultChecked checked={ui?.tm} />
        ),
        divider: true,
      },
      {
        name: "ui:view.highlights",
        disableClose: true,
        onClick: handleHightlight,
        end: ({ ui }: any) => (
          <IOSSwitch sx={{ ml: 2, mr: 0 }} checked={ui?.highlightTag} />
        ),
        divider: true,
      },
      {
        name: "ui:view.fullscreen",
        disableClose: true,
        onClick: handleFullscreen,
        end: ({ ui }: any) => (
          <IOSSwitch sx={{ ml: 2, mr: 0 }} checked={ui?.fullScreen} />
        ),
      },
    ],
  },
  {
    name: "ui:settings.title",
    icon: SettingsOutlined,
    modalKey: "settings",
  },
  {
    name: "ui:info.title",
    icon: InfoOutlined,
    key: "about",
    submenu: [
      {
        name: "ui:info.docs",
        icon: BookOutlined,
        divider: true,
        hideOnClick: true,
        onClick: () => () => {
          window.open(
            "https://docs.vivysub.app",
            "_blank",
            "frame=true,nodeIntegration=no, width=420, height=720, menu=false, autoHideMenuBar=true"
          );
        },
      },
      {
        name: "ui:info.website",
        icon: Web,
        hideOnClick: true,
        onClick: () => () => {
          shell.openExternal("https://vivysub.app");
        },
      },
      {
        name: "ui:info.bug_report",
        hideOnClick: true,
        icon: BugReport,
        onClick: () => () => {
          shell.openExternal("mailto:report@vivysub.app");
        },
      },
      {
        name: "Падтрымаць",
        icon: VolunteerActivismOutlinedIcon,
        divider: true,
        hideOnClick: true,
        onClick: () => () => {
          shell.openExternal("https://patreon.com/vivysub");
        },
      },
      {
        name: "ui:info.about",
        icon: InfoOutlined,
        onClick:
          ({ modal }: any) =>
          () => {
            modal.change("about", true);
          },
      },
    ],
  },
];
