import { createAction } from "../../helpers/createAction";
import { createSelectorHook } from "../../helpers/createSelectorHook";
import { createActionHook } from "../../helpers/createActionHook";
import { RootState } from "../rootReducer";
import set from "lodash/set";
import clone from "lodash/clone";

const ID = "ACCOUNT";

const changeAction = createAction(ID, ([key, value]) => ({ key, value }));
const changeHook = createActionHook(changeAction);
const getaccount = createSelectorHook<RootState["account"]>(
  () => (state: RootState) => state.account
);

const initialState = {};

export const accountReducer = (
  state: typeof initialState = initialState,
  action: any
) => {
  if (action.type.includes(ID)) {
    const { key, value } = action.payload;

    if (!key && typeof value === "function") {
      return value(state);
    }

    return clone(set(clone(state), key, value));
  } else {
    return state;
  }
};

export const useaccount = () => {
  const [change] = changeHook();
  const account = getaccount();

  return {
    ...account,
    change,
  };
};
