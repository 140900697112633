import { createAction } from "../../helpers/createAction";
import { createActionHook } from "../../helpers/createActionHook";
import {
  EntityDialogValue,
  EntityInfoValue,
  EntityStyleValue,
  Subtitles,
} from "../../types/subtitles";

import set from "lodash/set";
import clone from "lodash/clone";

const ID = "SUBTITLES";

const changeAction = createAction(ID, ([key, value]) => ({ key, value }));
export const changeSubtitles = createActionHook(changeAction);

const initialState = {
  dialogs: [],
  fonts: [],
  meta: {},
  original: [],
  styles: [],
  translated: [],
  history: [],
  info: [],
  currentSubtitle: null,
  actors: [],
  selected: {},
  filters: {
    style: -1,
    groupBy: true,
    actor: -1,
  },
};

export interface ISubtitlesReducerMeta {
  filePath?: string;
  fileName?: string;
  currentSubtitleId?: string;
}
export interface ISubtitlesHistory {
  name: string;
  path: string;
  addedAt: number;
}
export interface ISubtitlesReducer {
  dialogs: EntityDialogValue[];
  fonts: EntityStyleValue[];
  meta: ISubtitlesReducerMeta;
  original: Subtitles;
  styles: EntityStyleValue[];
  translated: Subtitles;
  history: ISubtitlesHistory[];
  info: EntityInfoValue[];
  actors: string[];
  currentSubtitle: (EntityDialogValue & { id: string }) | null;
  selected: {
    [key: string]: boolean;
  };
  filters: {
    style: string | null | number;
    groupBy: boolean;
    actor: string | null | number;
  };
}

export const subtitlesReducer: any = (
  state: ISubtitlesReducer = initialState,
  action: any
) => {
  if (action.type.includes(ID)) {
    const { key, value } = action.payload;
    if (ID.includes("translated")) {
      return {
        ...state,
        translated: value,
      };
    }

    if (!key && typeof value === "function") {
      return value(state);
    }

    return {
      ...state,
      ...clone(set(clone(state), key, value)),
    };
  } else {
    return state;
  }
};

export interface IUseSubtitles {
  change: (key: string | any, value?: any) => void;
}

export const useSubtitles = (): IUseSubtitles => {
  const [change] = changeSubtitles();

  return {
    change,
  };
};
