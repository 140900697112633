import { get } from "lodash";
import { useModal } from "../../../core/store/modal";
import AboutDialog from "./About";
import AddStyleDialog from "./AddStyle";
import ConfirmDialog from "./Confirm";
import EditActorDialog from "./EditActor";
import EditCommentDialog from "./EditComment";
import EditStyleDialog from "./EditStyle";
import EditTimingDialog from "./EditTiming";
import ExportDialog from "./Export";
import FontsDialog from "./Fonts";
import OpenVideoStreamDialog from "./OpenVideoStream";
import PropertiesDialog from "./Properties";
import React from "react";
import RenderDialog from "./Render";
import SearchAndReplaceDialog from "./SearchAndReplace";
import SearchDialog from "./Search";
import SelectTrackDialog from "./SelectTrack";
import SettingsDialog from "./Settings";
import StylesDetailsDialog from "./StylesDetails";
import StylesDialog from "./Styles";
import AuthorizationDialog from "./Authorization";

enum ModalsTypes {
  about = "about",
  addStyle = "add.style",
  editActor = "edit.actor",
  editComment = "edit.comment",
  editStyle = "edit.style",
  editTiming = "edit.timing",
  export = "export",
  fonts = "fonts",
  properties = "properties",
  search = "search",
  searchAndReplace = "searchAndReplace",
  selectTrack = "selectTrack",
  settings = "settings",
  styleDetails = "details.style",
  styles = "styles",
  confirm = "confirm",
  openVideoStream = "openVideoStream",
  render = "render",
  authorization = "authorization",
}

const modals = {
  [ModalsTypes.about]: AboutDialog,
  [ModalsTypes.addStyle]: AddStyleDialog,
  [ModalsTypes.editActor]: EditActorDialog,
  [ModalsTypes.editComment]: EditCommentDialog,
  [ModalsTypes.editStyle]: EditStyleDialog,
  [ModalsTypes.editTiming]: EditTimingDialog,
  [ModalsTypes.export]: ExportDialog,
  [ModalsTypes.fonts]: FontsDialog,
  [ModalsTypes.properties]: PropertiesDialog,
  [ModalsTypes.search]: SearchDialog,
  [ModalsTypes.searchAndReplace]: SearchAndReplaceDialog,
  [ModalsTypes.selectTrack]: SelectTrackDialog,
  [ModalsTypes.settings]: SettingsDialog,
  [ModalsTypes.styleDetails]: StylesDetailsDialog,
  [ModalsTypes.styles]: StylesDialog,
  [ModalsTypes.confirm]: ConfirmDialog,
  [ModalsTypes.openVideoStream]: OpenVideoStreamDialog,
  [ModalsTypes.render]: RenderDialog,
  [ModalsTypes.authorization]: AuthorizationDialog,
};

const Modals = () => {
  const modal = useModal();

  return (
    <React.Fragment>
      {Object.keys(modals).map((key: ModalsTypes) => {
        const Modal = modals[key] as any;

        return get(modal, key, false) ? (
          <Modal key={key} />
        ) : (
          <React.Fragment key={key} />
        );
      })}
    </React.Fragment>
  );
};

export default Modals;
