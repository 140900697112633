import { createAction } from "../../helpers/createAction";
import { createSelectorHook } from "../../helpers/createSelectorHook";
import { createActionHook } from "../../helpers/createActionHook";
import { RootState } from "../rootReducer";
import set from "lodash/set";
import clone from "lodash/clone";

const ID = "SETTINGS";

const changeAction = createAction(ID, ([key, value]) => ({ key, value }));
const changeHook = createActionHook(changeAction);
export const getSettings = createSelectorHook<RootState["settings"]>(
  () => (state: RootState) => state.settings
);

const initialState = {
  ui: {
    autoSave: true,
    dictionaries: true,
    drawerWidth: 360,
    firstLogin: true,
    mode: "light",
    spellchecker: false,
    tm: true,
    editDrawer: false,
    fullScreen: false,
    tagInList: false,
    highlightTag: true,
    pip: false,
    backdrop: false,
    title: "",
  },
  video: {},
  audio: {},
  fonts: {},
};

export interface IUISettings {
  autoSave: boolean;
  dictionaries: boolean;
  drawerWidth: number;
  firstLogin: boolean;
  mode: "light" | "dark" | string;
  spellchecker: boolean;
  tm: boolean;
  editDrawer: boolean;
  fullScreen: boolean;
  tagInList: boolean;
  highlightTag: boolean;
  pip: boolean;
  backdrop: boolean;
  title: string;
}

export interface IVideoSettings {}
export interface IAudioSettings {}
export interface IFontsSettings {}
export interface ISettingsReducer {
  ui: IUISettings;
  video: IVideoSettings;
  audio: IAudioSettings;
  fonts: IFontsSettings;
}

export const settingsReducer = (
  state: ISettingsReducer = initialState,
  action: any
) => {
  if (action.type.includes(ID)) {
    const { key, value } = action.payload;

    if (!key && typeof value === "function") {
      return value(state);
    }

    return clone(set(clone(state), key, value));
  } else {
    return state;
  }
};

export interface IUseSettings extends ISettingsReducer {
  change: (key: string, value: any) => void;
}

export const useSettings = (): IUseSettings => {
  const [change] = changeHook();
  const settings = getSettings();

  return {
    ...settings,
    change,
  };
};
