import { Subtitles } from "../types/subtitles";
import { getSubtitleSection, removeTags } from "./utils";
import { v4 } from "uuid";
import { clone, groupBy, uniqBy } from "lodash";

export const handleSubtitleStore = (
  path: string,
  subtitles: Subtitles,
  change: (key: string, value: any) => void,
  callback: () => void,
  importFile?: boolean
) => {
  const subtitlesWithIds = clone(subtitles).map((subtitle) => {
    return {
      ...subtitle,
      body: subtitle.body.map((entity) => {
        const id = v4();

        try {
          return {
            ...entity,
            value:
              !subtitle.section.includes("Events") ||
              entity.key.includes("Format")
                ? entity.value
                : {
                    ...entity.value,
                    id,
                  },
            id,
          };
        } catch (error) {
          return {
            ...entity,
            id,
          };
        }
      }),
    };
  });

  const info = getSubtitleSection(subtitlesWithIds, "Info");
  const styles = getSubtitleSection(subtitlesWithIds, "Styles");
  const dialogs = getSubtitleSection(subtitlesWithIds, "Events");
  const targetDialogs = dialogs
    .filter(({ key }) => key === "Dialogue")
    .map(({ value }) => value);

  const groupped = groupBy(
    targetDialogs.map((e) => {
      return {
        ...e,
        groupKey: removeTags(e.Text),
      };
    }),
    "groupKey"
  );

  const filtered = Object.entries(groupped).reduce(
    (acc: any, [key, value]: any) => {
      const [first] = value;
      const isMerged = value.length > 1;
      const target = {
        ...first,
        merged: isMerged,
        nested: isMerged ? value.map(({ id }: any) => id) : [],
      };

      acc.push(target as unknown as any);

      return acc;
    },
    []
  );

  if (!importFile) {
    change("meta.filePath", path);
  }

  change("info", info);
  change(
    "styles",
    styles.filter(({ key }) => key === "Style").map(({ value }) => value)
  );
  change("dialogs", filtered);
  change("original", subtitlesWithIds);
  change("translated", subtitlesWithIds);

  const actors = uniqBy(targetDialogs, "Name")
    .map(({ Name }) => Name)
    .filter(Boolean);
  change("actors", actors);

  const uniqFonts = uniqBy(
    styles.filter(({ key }) => key === "Style").map(({ value }) => value),
    "Fontname"
  );

  change("fonts", uniqFonts);

  window.wb.messageSW({ type: "SUBTITLES_INIT", payload: subtitlesWithIds });

  if (callback) {
    callback();
  }
};

export const clearSubtitles = (
  change: (key: string, value: any) => void,
  callback?: () => void
) => {
  change("meta.filePath", null);
  change("currentSubtitle", null);

  if (callback) {
    callback();
  }
};
