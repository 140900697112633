import React from "react";
import { Add, AddCircle, SubtitlesOutlined } from "@mui/icons-material";
import {
  Button,
  Fab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ISubtitlesHistory } from "../../../core/store/subtitles";
import { withWrapper } from "../../../core/helpers/wrapper";
import { useMainWrapper } from "./wrapper";
import { useDevice } from "../../../core/hooks/useDevice";

interface IRecentlyPage {
  history: ISubtitlesHistory[];
  openHistoryFile: (path: string) => void;
  handleUpload: () => void;
  t: (key: string) => string;
  currentTab: number;
  setCurrentTab: (value: number) => void;
}

const Recently = ({
  history,
  openHistoryFile,
  handleUpload,
}: IRecentlyPage) => {
  const { isMobile } = useDevice();
  return (
    <Box>
      {!isMobile && (
        <Button
          startIcon={<AddCircle />}
          variant={"gradient" as any}
          onClick={handleUpload}
        >
          Адкрыць файл
        </Button>
      )}

      <Paper
        elevation={1}
        sx={{
          mt: 2,
          height: isMobile ? "calc(100vh - 130px)" : "calc(100vh - 160px)",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box sx={styles.boxRecently}>
          <List
            sx={{
              width: "100%",
              p: 0,
              height: "100%",
              minHeight: "100%",
              flex: 1,
            }}
          >
            {history?.map(({ path, name }: any) => {
              return (
                <ListItem
                  button
                  sx={{ borderRadius: 0 }}
                  onClick={() => openHistoryFile(path)}
                >
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <SubtitlesOutlined />
                  </ListItemIcon>
                  <ListItemText primary={name} secondary={path} />
                </ListItem>
              );
            })}
            {!history?.length && (
              <Box
                sx={{
                  p: 2,
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  py: 6,
                  height: "100%",
                  minHeight: "100%",
                }}
              >
                <SubtitlesOutlined sx={{ fontSize: "4rem" }} />
                <Typography variant="h6" sx={{ mt: 1 }}>
                  Няма апошніх субцітраў
                </Typography>
              </Box>
            )}
          </List>
        </Box>
        {isMobile && (
          <Box sx={{ position: "absolute", right: 20, bottom: 30 }}>
            <Fab color="primary" size="large" onClick={handleUpload}>
              <Add />
            </Fab>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

const styles = {
  container: { minHeight: "100vh", m: "0 auto" },
  boxRecently: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "100%",
    flex: 1,
  } as any,
  boxAdd: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
  } as any,
};

export default withWrapper(Recently, useMainWrapper);
