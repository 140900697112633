import {
  AnimationOutlined,
  AssignmentOutlined,
  PersonOutlined,
  StyleOutlined,
} from "@mui/icons-material";
import AutoAwesomeMotionOutlinedIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";
import { Highlighter } from "highlight-react-text";
import {
  Chip,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo } from "react";

import SubMenu from "./SubMenu";

import { duractionInSeconds, removeTags } from "../../../core/helpers/utils";
import { useSubtitles } from "../../../core/store/subtitles";
import { useSettings } from "../../../core/store/settings";
import { EntityDialogValue } from "../../../core/types/subtitles";
import TimingChip from "./TimingChip";
import { subtitlesSelectors } from "../../../core/store/subtitles/selectors";
import { withWrapper } from "../../../core/helpers/wrapper";
import { useVivysub } from "../../../core/helpers/vivysub";
import { useKeyPress } from "../../../core/hooks/useKeyPress";

function RenderRowComponent({
  anchorEl,
  change,
  Comment,
  cps,
  defaultSubtitle,
  Effect,
  End,
  id,
  index,
  merged,
  Name,
  selected,
  setAnchorEl,
  Start,
  Style,
  style,
  subChange,
  text,
  theme,
  isHeldControl,
  ui,
  isActive,
}: any) {
  return (
    <div
      style={style}
      onContextMenu={(event: any) => {
        event.preventDefault();
        setAnchorEl({
          left: event.clientX,
          top: event.clientY,
        });
      }}
    >
      <ListItem
        key={index}
        dense
        divider
        button
        selected={Boolean(selected[id])}
        onClick={() => {
          subChange("currentSubtitle", defaultSubtitle);
          if (isHeldControl) {
            subChange("selected." + id, true);
          } else {
            subChange("selected", {
              [id]: true,
            });
          }
          subChange("meta.currentSubtitleId", id);
          change("ui.editDrawer", true);
        }}
        sx={{
          minHeight: 72,
          borderLeft: isActive ? `4px solid ${theme.palette.primary.main}` : "",
        }}
      >
        <ListItemIcon sx={{ minWidth: 48 }}>
          {merged ? (
            <Box
              sx={{
                width: 32,
                height: 32,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <AutoAwesomeMotionOutlinedIcon />
            </Box>
          ) : (
            <Box
              sx={{
                width: 32,
                height: 32,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                color: theme.palette.common.white,
                backgroundColor:
                  cps < 35
                    ? theme.palette.success.light
                    : cps < 80
                    ? theme.palette.warning.light
                    : theme.palette.error.light,
              }}
            >
              {cps > 999 ? ":D" : cps}
            </Box>
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            text ? (
              <b
                style={{
                  fontWeight: 500,
                  margin: 0,
                }}
              >
                {ui?.highlightTag ? (
                  <Highlighter
                    searchText={/{.*?}/ as any as string}
                    parentTag="div"
                  >
                    {text}
                  </Highlighter>
                ) : (
                  text
                )}
              </b>
            ) : (
              <i style={{ fontWeight: 500, color: theme.palette.warning.main }}>
                Пусты радок
              </i>
            )
          }
          primaryTypographyProps={{
            noWrap: true,
          }}
          secondary={
            <>
              <Hidden mdDown>
                <Box sx={{ mt: 0.5 }}>
                  <TimingChip start={Start} end={End} id={id} />
                  {!!Style && (
                    <Chip
                      icon={<StyleOutlined />}
                      sx={{ lineHeight: "24px", ml: 1 }}
                      label={
                        <p
                          style={{
                            minHeight: 24,
                            maxHeight: 24,
                            height: 24,
                            lineHeight: "22.5px",
                          }}
                        >
                          {Style}
                        </p>
                      }
                      size="small"
                    />
                  )}

                  {Name && (
                    <Chip
                      icon={<PersonOutlined />}
                      sx={{ lineHeight: "24px", ml: 1 }}
                      label={
                        <p
                          style={{
                            minHeight: 24,
                            maxHeight: 24,
                            height: 24,
                            lineHeight: "22.5px",
                          }}
                        >
                          {Name}
                        </p>
                      }
                      size="small"
                    />
                  )}
                  {Effect && (
                    <Chip
                      icon={<AnimationOutlined />}
                      sx={{ lineHeight: "24px", ml: 1 }}
                      label={
                        <p
                          style={{
                            minHeight: 24,
                            maxHeight: 24,
                            height: 24,
                            lineHeight: "22.5px",
                          }}
                        >
                          {Effect}
                        </p>
                      }
                      size="small"
                    />
                  )}
                  {Comment && (
                    <Chip
                      icon={<AssignmentOutlined />}
                      sx={{ lineHeight: "24px", ml: 1 }}
                      label={
                        <p
                          style={{
                            minHeight: 24,
                            maxHeight: 24,
                            height: 24,
                            lineHeight: "22.5px",
                          }}
                        >
                          Каментарый
                        </p>
                      }
                      size="small"
                    />
                  )}
                </Box>
              </Hidden>
              <Hidden mdUp>
                {Start} {"->"} {End}
              </Hidden>
            </>
          }
        />
        <ListItemSecondaryAction sx={{ mr: -1 }}>
          <SubMenu {...{ anchorEl, setAnchorEl, subtitle: defaultSubtitle }} />
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  );
}

const useRenderRowWrapper = (options: any, props: any) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const { index, style } = props;
  const { change: subChange } = useSubtitles();
  const { vivysub, version } = useVivysub();
  const filters = subtitlesSelectors.getFilters();

  const dialogs = useMemo(() => {
    return vivysub.getFilteredEvents({
      onlyDialogs: true,
      styles: typeof filters.style === "string" ? [filters.style] : [],
      actors: typeof filters.actor === "string" ? [filters.actor] : [],
      groupped: typeof filters.groupBy === "boolean" ? filters.groupBy : true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version, filters]);

  const selected = subtitlesSelectors.getSelection();
  const meta = subtitlesSelectors.getMeta();
  const { ui, change } = useSettings();

  const defaultSubtitle = useMemo(() => {
    return dialogs.find((_: any, i: number) => index === i);
  }, [dialogs, index]);

  const { id, Text, merged, End, Start, Effect, Name, Style, Comment } =
    (defaultSubtitle as EntityDialogValue) || {};
  const isActive = id === meta.currentSubtitleId;

  const translation =
    defaultSubtitle?.Text || defaultSubtitle?.OriginalText || Text;

  const textWithSpace = translation
    ? translation.replace("\n", " {перанос} ").replace("\\N", " {перанос} ")
    : "";

  const text = ui.tagInList ? textWithSpace : removeTags(textWithSpace);
  const duractionSecond = duractionInSeconds(Start, End);
  const cps = +Number(
    removeTags(translation).length / duractionSecond
  ).toFixed();

  const isHeldCommand = useKeyPress("Meta");
  const isHeldAlt = useKeyPress("alt");
  const isHeldControl = isHeldCommand || isHeldAlt;

  return {
    anchorEl,
    change,
    Comment,
    cps,
    defaultSubtitle,
    Effect,
    End,
    id,
    index,
    merged,
    Name,
    selected,
    setAnchorEl,
    Start,
    Style,
    style,
    subChange,
    text,
    theme,
    ui,
    isActive,
    isHeldControl,
  };
};

const withWrapperComponent = withWrapper(
  RenderRowComponent,
  useRenderRowWrapper
);

export const RenderRow = React.memo(withWrapperComponent);
