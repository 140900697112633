/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSubtitles } from "../../../core/store/subtitles";
import { getSubtitleFile } from "../../../core/helpers/files";
import { readTextFile } from "../../../core/helpers/utils";
import { uniqBy } from "lodash";
import { useSettings } from "../../../core/store/settings";
import { subtitlesSelectors } from "../../../core/store/subtitles/selectors";
import { useTranslation } from "react-i18next";
import { useVivysub } from "../../../core/helpers/vivysub";

export const useMainWrapper = () => {
  const { change: changeSettings } = useSettings();
  const { change } = useSubtitles();
  const meta = subtitlesSelectors.getMeta();
  const { t } = useTranslation();
  const { vivysub } = useVivysub();
  const history = subtitlesSelectors.getHistory();
  const [currentTab, setCurrentTab] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    changeSettings("ui.title", "Апошнія файлы");
  }, []);

  useEffect(() => {
    if (meta.filePath) {
      navigate("/edit");
    }
  }, [meta.filePath, navigate]);

  const handleUpload = useCallback(async () => {
    changeSettings("ui.backdrop", true);
    const { content, path } = await getSubtitleFile();
    if (!content || !path) {
      return changeSettings("ui.backdrop", false);
    }

    vivysub.init(content);

    navigate("/edit");
    change("meta.filePath", path);
    change(
      "history",
      uniqBy(
        [...history, { name: path, path, addedAt: new Date().getTime() }],
        "path"
      )
    );

    changeSettings("ui.backdrop", false);
  }, [change, navigate, history, changeSettings]);

  const openHistoryFile = async (path: string) => {
    changeSettings("ui.backdrop", true);
    const content = await readTextFile(path);

    vivysub.init(content);

    navigate("/edit");
    change("meta.filePath", path);
    change(
      "history",
      uniqBy(
        [...history, { name: path, path, addedAt: new Date().getTime() }],
        "path"
      )
    );

    changeSettings("ui.backdrop", false);
  };

  return {
    history,
    handleUpload,
    openHistoryFile,
    t,
    currentTab,
    setCurrentTab,
  };
};
