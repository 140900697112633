import { createAction } from "../../helpers/createAction";
import { createSelectorHook } from "../../helpers/createSelectorHook";
import { createActionHook } from "../../helpers/createActionHook";
import { RootState } from "../rootReducer";
import set from "lodash/set";
import clone from "lodash/clone";

const ID = "VIDEO";

const changeAction = createAction(ID, ([key, value]) => ({ key, value }));
const changeHook = createActionHook(changeAction);
const getVideo = createSelectorHook<RootState["video"]>(
  () => (state: RootState) => state.video
);

const initialState = {
  meta: {
    filePath: null,
  },
  settings: {},
  audio: {},
};

export interface IVideoReducerMeta {
  filePath: string | null;
}

export interface IVideoReducerSettings {}

export interface IVideoReducerAudio {}

export interface IVideoReducer {
  meta: IVideoReducerMeta;
  settings: IVideoReducerSettings;
  audio: IVideoReducerAudio;
}

export const videoReducer = (
  state: IVideoReducer = initialState,
  action: any
) => {
  if (action.type.includes(ID)) {
    const { key, value } = action.payload;

    if (!key && typeof value === "function") {
      return value(state);
    }

    return clone(set(clone(state), key, value));
  } else {
    return state;
  }
};

export interface IUseVideo extends IVideoReducer {
  change: (key: string, value: any) => void;
}

export const useVideo = (): IUseVideo => {
  const [change] = changeHook();
  const video = getVideo();

  return {
    ...video,
    change,
  };
};
