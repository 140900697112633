import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import {
  CloudOutlined,
  FolderOutlined,
  PersonOutlined,
} from "@mui/icons-material";

export default function MobileNavigation({ onChange, currentTab }: any) {
  const [value, setValueHandler] = React.useState(currentTab || 0);

  const setValue = (value: number) => {
    setValueHandler(value);

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        paddingBottom: "env(safe-area-inset-bottom)",
        marginBottom: "-env(safe-area-inset-bottom)",
        background: (theme) => theme.palette.background.paper,
      }}
    >
      <BottomNavigation
        showLabels
        sx={{
          width: "100%",
          minHeight: 68,
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="Recents"
          icon={<RestoreIcon />}
          sx={{ p: 2 }}
        />
        <BottomNavigationAction
          label="Projects"
          icon={<FolderOutlined />}
          sx={{ p: 2 }}
        />
        <BottomNavigationAction
          label="Cloud"
          icon={<CloudOutlined />}
          sx={{ p: 2 }}
        />
        <BottomNavigationAction
          label="Account"
          icon={<PersonOutlined />}
          sx={{ p: 2 }}
        />
      </BottomNavigation>
    </Box>
  );
}
