import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Button,
  DialogActions,
  Divider,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";

import { useModal } from "../../../core/store/modal";
import assParser from "ass-parser";
import { Box } from "@mui/system";
import { getDataString, msSubtitle } from "../../../core/helpers/utils";

import assStringify from "ass-stringify";
import { useTranslation } from "react-i18next";
import { useVivysub } from "../../../core/helpers/vivysub";

function SelectTrackDialog() {
  const modal = useModal();
  const { t } = useTranslation();
  const { vivysub } = useVivysub();
  const [value, setValue] = useState(-1);

  const handleClose = () => {
    modal.change("edit.selectTrack", false);
    modal.change("props", {});
  };

  const handleClick = () => {
    const track = modal?.props?.find(({ name }: any) => name === value);

    if (track?.header) {
      const content = track?.header;
      let subtitles = assParser(content, { comments: true });
      subtitles = subtitles.map((sub: any) => {
        if (sub.section === "Events") {
          return {
            ...sub,
            body: [
              ...sub.body,
              ...track.subtitles.map((obj: any) => {
                const time = obj.time;
                const end = obj.duration;
                return {
                  key: "Dialogue",
                  value: {
                    Start: msSubtitle(time),
                    End: msSubtitle(time + end),
                    Text: obj.text,
                    Layer: obj.layer,
                    Style: obj.style,
                    Name: obj.name,
                    MarginL: obj.marginL,
                    MarginR: obj.marginR,
                    MarginV: obj.marginV,
                  },
                };
              }),
            ],
          };
        }
        return sub;
      });

      subtitles = assStringify(subtitles);
      vivysub.init(subtitles);
      handleClose();
    }
  };

  if (!Array.isArray(modal?.props)) {
    return null;
  }

  return (
    <div>
      <Dialog open={modal?.selectTrack} onClose={handleClose}>
        <DialogTitle>{t("modals:select_track.title")}</DialogTitle>
        <DialogContent sx={{ minWidth: 460 }}>
          <Box sx={{ mt: 2 }}>
            <TextField
              select
              autoComplete="on"
              fullWidth
              size="small"
              value={value}
              onChange={({ target: { value } }: any) => setValue(value)}
            >
              <MenuItem key={-1} value={-1} disabled>
                {t("modals:select_track.select")}
              </MenuItem>
              {modal?.props?.map(({ name, type, language }: any) => {
                return (
                  <MenuItem key={name} value={name} dense>
                    <ListItemText
                      primary={name}
                      secondary={getDataString([`Fromat: ${type}`, language])}
                    />
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose}>{t("ui:common.cancel")}</Button>
          <Button variant={"gradient" as any} onClick={handleClick}>
            {t("ui:common.apply")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SelectTrackDialog;
