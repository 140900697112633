import IndexDB from "redux-persist-indexeddb-storage";
export const hasElectron = "electron" in window;

const storage = IndexDB("vivysub-db");

console.log(storage);

if (!hasElectron) {
  window.electron = {
    fs: {},
    storage: storage,
    electronStore: {
      get: (key: string) => storage.getItem(key),
      set: (key: string, value: any) => storage.setItem(key, value),
    },
    shell: {
      openExternal: (url: string) => {
        window.open(url, "_blank");
      },
    },
    async openDialog(type: string, options: any) {
      if (type === "showSaveDialog") {
        return;
      }

      const file = new Promise((res) => {
        let input = document.createElement("input");
        input.type = "file";
        input.onchange = (e: any) => {
          res(e.target.files[0]);
        };

        input.click();
      });

      const result = await file;

      if (!result) {
        return {
          filePaths: [],
          canceled: true,
        };
      }

      return {
        filePaths: [result],
        canceled: false,
      };
    },
  } as any;
}
