import { readTextFile, fileToString } from "./utils";

export const getSubtitleFile = async () => {
  const { filePaths, canceled } = await window.electron.openDialog(
    "showOpenDialog",
    {
      properties: ["openFile", "noResolveAliases"],
      filters: [
        {
          name: "Subtitles",
          extensions: ["ass", "ssa"],
        },
      ],
    }
  );

  if (canceled) {
    return { content: null, path: null };
  }

  let [path] = filePaths;
  let content: any;
  if (typeof path === "object") {
    content = await fileToString(path);
    path = path.name;
  } else {
    content = await readTextFile(path);
  }

  return { content, path };
};

export const getVideoFile = async () => {
  const { filePaths, canceled } = await window.electron.openDialog(
    "showOpenDialog",
    {
      properties: ["openFile", "noResolveAliases"],
      filters: [
        {
          name: "Video",
          extensions: ["mp4", "mkv"],
        },
      ],
    }
  );

  if (canceled) {
    return { content: null, path: null };
  }

  const [path] = filePaths;

  let videoLink: any;
  if (typeof path === "object") {
    videoLink = URL.createObjectURL(path);
  } else {
    videoLink = path;
  }

  console.log(videoLink);

  return videoLink;
};

export const importSubtitles = async (): Promise<{
  content: any;
  isVideo: boolean;
  path: string;
}> => {
  const { filePaths, canceled } = await window.electron.openDialog(
    "showOpenDialog",
    {
      properties: ["openFile", "noResolveAliases"],
      filters: [
        {
          name: "Video & Subtitles",
          extensions: ["mkv", "ass", "ssa"],
        },
      ],
    }
  );

  if (canceled) {
    return { content: null, isVideo: false, path: "" };
  }

  const [path] = filePaths;
  let content;
  const isVideo = path.endsWith(".mkv");
  if (isVideo) {
    content = await window.electron.parseSubtitleFromVideo(path);
  } else {
    content = await readTextFile(path);
  }

  return {
    content,
    isVideo,
    path,
  };
};
