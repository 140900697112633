import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { Button, DialogActions, Divider, TextField } from "@mui/material";

import { useModal } from "../../../core/store/modal";
import { EntityStyleValue } from "../../../core/types/subtitles";
import { useTranslation } from "react-i18next";

const defaultStyle = {
  id: "default",
  Name: "Default",
  Fontname: "Montserrat Bold",
  Fontsize: "72",
  PrimaryColour: "&H00FFFFFF",
  SecondaryColour: "&H000000FF",
  OutlineColour: "&H00000000",
  BackColour: "&H00000000",
  Bold: "0",
  Italic: "0",
  Underline: "0",
  StrikeOut: "0",
  ScaleX: "100",
  ScaleY: "115",
  Spacing: "0.7",
  Angle: "0",
  BorderStyle: "1",
  Outline: "3",
  Shadow: "0",
  Alignment: "2",
  MarginL: "3",
  MarginR: "3",
  MarginV: "45",
  Encoding: "0",
};

function AddStyleDialog() {
  const modal = useModal();
  const { t } = useTranslation();
  const style = modal.props as EntityStyleValue;
  const [state, setState] = useState<EntityStyleValue>(defaultStyle);

  const handleClose = () => {
    modal.change("add.style", false);
    modal.change("props", {});
  };

  return (
    <div>
      <Dialog open={modal.add.style} onClose={handleClose}>
        <DialogTitle>{t("modals:add_style.title")}</DialogTitle>
        <DialogContent sx={{ minWidth: 460 }}>
          {Object.keys(style).map((key) => {
            const value = (style as any)[key];
            return (
              <TextField
                size="small"
                defaultValue={value}
                value={(state as any)[key]}
                label={key}
                key={key}
                fullWidth
                sx={{ mt: 2 }}
                onChange={({ target: { value } }) => {
                  setState({
                    ...state,
                    [key]: value,
                  });
                }}
              />
            );
          })}
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose}>{t("ui:common.cancel")}</Button>
          <Button variant={"gradient" as any}>{t("ui:common.create")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(AddStyleDialog);
