import { CloseOutlined } from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSettings } from "../../../core/store/settings";
import SwipeableViews from "react-swipeable-views";

const StartPage = () => {
  const { change } = useSettings();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        background: (theme) => theme.palette.background.paper,
      }}
    >
      <div style={{ WebkitUserSelect: "none", WebkitAppRegion: "drag" } as any}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
          <IconButton
            onClick={() => {
              window.close();
            }}
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </Box>
      </div>
      <SwipeableViews
        enableMouseEvents
        animateHeight
        containerStyle={{
          minHeight: "calc(100vh - 68px)",
          maxHeight: "calc(100vh - 68px)",
        }}
        slideStyle={{
          minHeight: "100%",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <img
            alt="logo"
            src="./logo.png"
            style={{ width: 80, borderRadius: "50%" }}
          />
          <Typography component={"span"} variant="h3" sx={{ mt: 2 }}>
            {t("pages:start.label")}
          </Typography>
          <Typography component={"span"} variant="body1" sx={{ mb: 12 }}>
            {t("pages:start.sublabel")}
            <Typography variant="body1" sx={{ mt: 2, whiteSpace: "pre-line" }}>
              {t("pages:start.description")}
            </Typography>
          </Typography>
          <Button
            variant={"gradient" as any}
            onClick={() => {
              change("ui.firstLogin", false);
            }}
          >
            {t("pages:start.button")}
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          next
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          next
        </Box>
      </SwipeableViews>
    </Box>
  );
};

export default StartPage;
