import { clearSubtitles } from "../../core/helpers/store.utils";
import { RootState } from "../../core/store/rootReducer";

import {
  getSubtitleFile,
  getVideoFile,
  importSubtitles,
} from "../../core/helpers/files";
import { VivySubtitles } from "../../core/helpers/vivysub";

interface IProps {
  change: any;
  modal: any;
  navigate: any;
  ui: any;
  changeSettings: any;
  changeVideo: any;
  video: RootState["video"];
  subtitles: RootState["subtitles"];
  meta: RootState["subtitles"]["meta"];
  vivysub: VivySubtitles;
  t: any;
  enqueueSnackbar: any;
}

const emptySubtitle = `[Script Info]
Title: Default VivySup file
ScriptType: v4.00+
WrapStyle: 0
ScaledBorderAndShadow: yes
YCbCr Matrix: None

[V4+ Styles]
Format: Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding
Style: Default,Montserrat,72,&H00FFFFFF,&H000000FF,&H00020713,&H00000000,-1,0,0,0,100,115,0,0,1,3.9,0,2,300,300,45,1

[Events]
Format: Layer, Start, End, Style, Name, MarginL, MarginR, MarginV, Effect, Text
Dialogue: 0,0:00:00.00,0:00:05.00,Default,,0,0,0,,
`;

export const handleRender = (props: IProps) => async () => {
  props.modal.change("render", true);
};

export const handleCreateSubtitle =
  ({ change, navigate, vivysub, changeVideo }: IProps) =>
  async () => {
    vivysub.init(emptySubtitle);
    changeVideo("meta.filePath", null);
    change("meta.filePath", "/new-subtitle");
    navigate("/edit");
  };

export const handleOpenSubtitle =
  ({ vivysub, change, navigate, changeSettings, changeVideo }: IProps) =>
  async () => {
    changeSettings("ui.backdrop", true);
    const { content, path } = await getSubtitleFile();
    if (!content || !path) {
      return changeSettings("ui.backdrop", false);
    }

    vivysub.init(content);
    changeVideo("meta.filePath", null);
    change("meta.filePath", path);
    navigate("/edit");
    changeSettings("ui.backdrop", false);
  };

export const handleSaveSubtitle =
  ({ meta, vivysub, change, changeSettings }: IProps) =>
  async () => {
    changeSettings("ui.backdrop", true);
    const result = vivysub.export();
    let filePath = meta.filePath;

    if (!filePath || filePath === "/new-subtitle") {
      const options = {
        title: "Save file",
        defaultPath: filePath || "",
        buttonLabel: "Save",
        filters: [{ name: "subtitles", extensions: [".ass"] }],
      };

      const res = await window.electron.openDialog("showSaveDialog", options);

      if (res?.filePath) {
        filePath = res?.filePath;

        change("meta.filePath", filePath);
      }
    }

    if (filePath) {
      window.electron.fs.writeFileSync(filePath, result, "utf-8");
    }

    changeSettings("ui.backdrop", false);
    vivysub.setIsChanged(false);
  };

export const handleSaveAs =
  ({ vivysub, meta, t, enqueueSnackbar, changeSettings }: IProps) =>
  async () => {
    changeSettings("ui.backdrop", true);
    const result = vivysub.export();
    const options = {
      title: t("modals:export.dialogs.save_as"),
      defaultPath: meta.filePath,
      buttonLabel: t("ui:common.save"),

      filters: [{ name: "subtitles", extensions: [".ass"] }],
    };

    const { filePath } = await window.electron.openDialog(
      "showSaveDialog",
      options
    );

    if (!filePath) {
      enqueueSnackbar(t("modals:export.messages.error"), {
        variant: "error",
      });
      changeSettings("ui.backdrop", false);
      return;
    }

    window.electron.fs.writeFileSync(filePath, result, "utf-8");
    changeSettings("ui.backdrop", false);
    vivysub.setIsChanged(false);
    enqueueSnackbar(t("modals:export.messages.success"), {
      variant: "success",
    });
  };

export const handleRecenlty =
  ({ vivysub, change, changeSettings }: IProps) =>
  () => {
    vivysub.reset();
    clearSubtitles(change);
    changeSettings("ui.editDrawer", false);
  };

export const handleProperties =
  ({ modal }: IProps) =>
  () => {
    modal.change("properties", true);
  };

export const handleFonts =
  ({ modal }: IProps) =>
  () => {
    modal.change("fonts", true);
  };

export const handleStyles =
  ({ modal }: IProps) =>
  () => {
    modal.change("styles", true);
  };

export const handleSearch =
  ({ modal }: IProps) =>
  async () => {
    modal.change("search", true);
  };

export const handleFindAndReplace =
  ({ modal }: IProps) =>
  async () => {
    modal.change("searchAndReplace", true);
  };

export const handleVideoOpen =
  ({ changeVideo, changeSettings }: IProps) =>
  async () => {
    changeSettings("ui.backdrop", true);
    const path = await getVideoFile();

    if (typeof path === "string") {
      if (path.includes("blob")) {
        changeVideo("meta.filePath", path);
      } else {
        changeVideo("meta.filePath", "file://" + path);
      }
    }

    changeSettings("ui.backdrop", false);
  };

export const handleVideoStream =
  ({ changeVideo, changeSettings, modal }: IProps) =>
  async () => {
    changeSettings("ui.backdrop", true);
    modal.change("openVideoStream", true);
    modal.change("props", () => {
      changeSettings("ui.backdrop", false);
    });
  };

export const handleVideoImport =
  ({ changeVideo, vivysub, changeSettings, enqueueSnackbar, modal }: IProps) =>
  async () => {
    changeSettings("ui.backdrop", true);
    const { content, isVideo, path } = await importSubtitles();
    if (!content && !path) {
      return changeSettings("ui.backdrop", false);
    }

    if (isVideo) {
      modal.change("selectTrack", true);
      modal.change("props", content);
      changeSettings("ui.backdrop", false);
      return;
    }

    if (!content && path) {
      enqueueSnackbar("Файн пашкоджаны", { variant: "error" });
      changeSettings("ui.backdrop", false);
      return;
    }

    vivysub.init(content);
    changeVideo("meta.filePath", path);
    changeSettings("ui.backdrop", false);
  };

export const handleVideoRemove =
  ({ changeVideo }: IProps) =>
  () => {
    changeVideo("meta.filePath", null);
  };

export const handleTheme =
  ({ ui, changeSettings }: IProps) =>
  () => {
    const v = ui?.mode === "dark" ? "light" : "dark";
    changeSettings("ui.mode", v);
  };

export const handleTags =
  ({ ui, changeSettings }: IProps) =>
  () => {
    const v = !ui?.tagInList;
    changeSettings("ui.tagInList", v);
  };

export const handleSpellchecker =
  ({ ui, changeSettings }: IProps) =>
  () => {
    const v = !ui?.spellchecker;
    changeSettings("ui.spellchecker", v);
  };

export const handleDictionaries =
  ({ ui, changeSettings }: IProps) =>
  () => {
    const v = !ui?.dictionaries;
    changeSettings("ui.dictionaries", v);
  };

export const handleGlossaries =
  ({ ui, changeSettings }: IProps) =>
  () => {
    const v = !ui?.tm;
    changeSettings("ui.tm", v);
  };

export const handleHightlight =
  ({ ui, changeSettings }: IProps) =>
  () => {
    const v = !ui?.highlightTag;
    changeSettings("ui.highlightTag", v);
  };

export const handleFullscreen =
  ({ ui, changeSettings }: IProps) =>
  () => {
    const v = !ui?.fullScreen;
    changeSettings("ui.fullScreen", v);
    if (v) {
      window.electron.ipcRenderer.send("fullScreen", "true");
    } else {
      window.electron.ipcRenderer.send("minScreen", "true");
    }
  };

export const handleExist =
  ({ modal }: IProps) =>
  () => {
    modal.change((state: RootState["modal"]) => ({
      ...state,
      confirm: true,
      props: {
        onConfirm: () => window.close(),
        text: "Сапраўды жадаеце выйсці?",
      },
    }));
  };
