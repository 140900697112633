import { Subtitles } from "../types/subtitles";

const fs = window.electron.fs;

export const timeToSeconds = (time: string = "0:03:14.54"): number => {
  const [hours, minutes, seconds] = time.split(":").map(Number);

  return +Number(hours * 60 * 60 + minutes * 60 + seconds).toFixed(2);
};

export const msSubtitle = (ms: number) => {
  const seconds = (ms / 1000) % 60;
  const minutes = Math.floor((ms / (60 * 1000)) % 60);
  const hours = Math.floor((ms / (3600 * 1000)) % 3600);
  return `${hours}:${minutes < 10 ? "0" + minutes : minutes}:${
    seconds < 10 ? "0" + seconds.toFixed(2) : seconds.toFixed(2)
  }`;
};

export const duractionInSeconds = (start: string, end: string) => {
  const result = timeToSeconds(end) - timeToSeconds(start);
  if (result === 0) {
    return 1;
  }

  return result;
};

export async function readTextFile(path: string) {
  const file = await fs.promises.readFile(path, "utf8");

  return file;
}

export async function fileToString(file: File) {
  const result = await file.text();

  return result;
}

export const getDataString = (
  arrayOfString: string[],
  seporator: string = " • "
) => {
  return arrayOfString.filter(Boolean).reduce((acc, address, index, arr) => {
    if (arr.length !== index + 1) {
      return acc + address + seporator;
    }

    return acc + address;
  }, "");
};

export const removeTags = (subtitle: string = ""): string => {
  return subtitle?.replaceAll(/{.*?}/gim, "") || "";
};

export const getVideoPercentage = (v: {
  currentTime: number;
  duration: number;
}) => {
  if (!v) {
    return 0;
  }

  const r = v.currentTime;
  const total = v.duration;

  return Number((r / total) * 100);
};

export const getSubtitleSection = (
  subtitles: any[],
  section: string
): any[] => {
  return subtitles?.find((s) => s.section.includes(section))?.body || [];
};

export const translateSubtitles = (
  id: string,
  value: string,
  subtitles: Subtitles
) => {
  return subtitles.map((subtitle) => {
    if (subtitle.section === "Events") {
      return {
        ...subtitle,
        body: subtitle.body.map((entity) => {
          if (entity.id === id) {
            return {
              ...entity,
              value: {
                ...entity.value,
                Text: value,
              },
            };
          }

          return entity;
        }),
      };
    }

    return subtitle;
  });
};

export const translateSubtitlesKey = (
  id: string,
  key: string,
  value: string,
  subtitles: Subtitles
) => {
  return subtitles.map((subtitle) => {
    if (subtitle.section === "Events") {
      return {
        ...subtitle,
        body: subtitle.body.map((entity) => {
          if (entity.id === id) {
            return {
              ...entity,
              value: {
                ...entity.value,
                [key]: value,
              },
            };
          }

          return entity;
        }),
      };
    }

    return subtitle;
  });
};

export const getTranslatedSubtitleById = (id: string, subtitles: Subtitles) => {
  const dialogs = getSubtitleSection(subtitles, "Events");

  return dialogs?.find((dialog) => id === dialog.id)?.value;
};

export const compareSubtitles = (translated: Subtitles, origin: Subtitles) => {
  const dialogs = getSubtitleSection(origin, "Events");
  return translated.map((sub) => {
    if (sub.section === "Events") {
      return {
        ...sub,
        body: sub.body.map((s) => {
          if (s.key === "Format") {
            return s;
          }

          const originSub = dialogs.find(({ id }) => id === s.id);

          return {
            ...s,
            value: {
              ...s.value,
              Text:
                !s.value.Text && originSub
                  ? originSub.value.Text
                  : s.value.Text,
            },
          };
        }),
      };
    }

    return sub;
  });
};

export const removeSubtitleById = (id: string, subtitles: Subtitles) => {
  return subtitles.map((subtitle) => {
    if (subtitle.section === "Events") {
      return {
        ...subtitle,
        body: subtitle.body.filter((entity) => entity.id !== id),
      };
    }

    return subtitle;
  });
};

export const setNewSubtitle = (translated: Subtitles, subtitleNew: any) => {
  return translated.map((subtitle) => {
    if (subtitle.section === "Events") {
      return {
        ...subtitle,
        body: [
          {
            key: "Format",
            value: [
              "Layer",
              "Start",
              "End",
              "Style",
              "Name",
              "MarginL",
              "MarginR",
              "MarginV",
              "Effect",
              "Text",
            ],
          },
          subtitleNew,
        ],
      };
    }

    return subtitle;
  });
};
