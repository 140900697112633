/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { Button, DialogActions, Divider, Grid, TextField } from "@mui/material";

import { useModal } from "../../../core/store/modal";

import { EntityDialogValue } from "../../../core/types/subtitles";
import { TimelapseOutlined } from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { useVivysub } from "../../../core/helpers/vivysub";

function EditTimingDialog() {
  const modal = useModal();

  const { t } = useTranslation();
  const { vivysub } = useVivysub();

  const subtitle = modal.props as EntityDialogValue;
  const [state, setState] = useState({
    Start: subtitle.Start,
    End: subtitle.End,
    error: {
      Start: false,
      End: false,
    },
  });

  const handleClose = () => {
    modal.change("edit.timing", false);
    modal.change("props", {});
  };

  const handleChange =
    (key: string) =>
    ({ target: { value } }: any) => {
      const reg = /^\d:\d\d:\d\d\.\d\d/gs;

      setState({
        ...state,
        [key]: value,
        error: {
          ...state.error,
          [key]: !reg.test(value),
        },
      });
    };

  const handleSave = () => {
    const id = modal?.props?.id;

    if (id) {
      vivysub.changeEvent(id, {
        Start: state.Start,
        End: state.End,
      });

      handleClose();
    }
  };

  return (
    <div>
      <Dialog open={modal?.edit?.timing} onClose={handleClose}>
        <DialogTitle>{t("modals:edit_timing.title")}</DialogTitle>
        <DialogContent sx={{ minWidth: 460 }}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item>
              <TextField
                fullWidth
                value={state.Start}
                onChange={handleChange("Start")}
                placeholder="0:00:00.00"
                label={t("modals:edit_timing.start")}
                InputProps={{
                  startAdornment: <TimelapseOutlined sx={{ mr: 1 }} />,
                }}
                error={state.error.Start}
                helperText="0:00:00.00"
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                value={state.End}
                label={t("modals:edit_timing.end")}
                onChange={handleChange("End")}
                placeholder="0:00:00.00"
                InputProps={{
                  startAdornment: <TimelapseOutlined sx={{ mr: 1 }} />,
                }}
                error={state.error.End}
                helperText="0:00:00.00"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose}>{t("ui:common.cancel")}</Button>
          <Button
            variant={"gradient" as any}
            onClick={handleSave}
            disabled={state.error.Start || state.error.End}
          >
            {t("ui:common.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditTimingDialog;
