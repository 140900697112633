/* eslint-disable react-hooks/exhaustive-deps */
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Menu,
  useTheme,
  MenuItem,
  Divider,
  IconButton,
  Avatar,
  Hidden,
  Drawer,
  ListItemSecondaryAction,
} from "@mui/material";

import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../core/store/modal";
import { useSettings } from "../../core/store/settings";
import { useSubtitles } from "../../core/store/subtitles";
import { useVideo } from "../../core/store/video";
import { options } from "./menubarOptions";
import { subtitlesSelectors } from "../../core/store/subtitles/selectors";
import { useTranslation } from "react-i18next";
import { useVivysub } from "../../core/helpers/vivysub";
import { useSnackbar } from "notistack";

import { KeyboardArrowDown, MenuOutlined, MoreVert } from "@mui/icons-material";
import { getDataString } from "../../core/helpers/utils";
import { useDevice } from "../../core/hooks/useDevice";
import { hasElectron } from "../../core/helpers/polyfill";

const { ipcRenderer, isMac } = window.electron;

function Submenu({
  data: { modalKey, name, icon: Icon, key, submenu, danger },
}: any) {
  const modal = useModal();
  const navigate = useNavigate();
  const { ui, change: changeSettings } = useSettings();
  const { change } = useSubtitles();
  const meta = subtitlesSelectors.getMeta();
  const translated = subtitlesSelectors.getTranslated();
  const { change: changeVideo, ...video } = useVideo();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const { vivysub } = useVivysub();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = {
    change,
    modal,
    navigate,
    ui,
    changeSettings,
    changeVideo,
    video,
    subtitles: { translated },
    meta,
    vivysub,
    enqueueSnackbar,
    t,
  };

  return (
    <React.Fragment key={name}>
      <ListItem
        button
        selected={open}
        sx={{ borderRadius: 0.5 }}
        onClick={(e) => {
          if (modalKey) {
            modal.change(modalKey, true);
          }

          if (key) {
            handleClick(e);
          }
        }}
      >
        <ListItemIcon sx={{ minWidth: 26 }}>
          <Icon sx={{ fontSize: 18, color: "primary.main" }} />
        </ListItemIcon>
        <ListItemText
          primary={t(name)}
          primaryTypographyProps={{
            sx: {
              whiteSpace: "nowrap",
              cursor: "pointer",
            },
          }}
        />
      </ListItem>
      {!!submenu?.length && (
        <div>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            MenuListProps={{
              dense: false,
            }}
            sx={{
              mt: hasElectron ? 9 : 6,
            }}
            PaperProps={{
              elevation: 2,
            }}
          >
            {submenu
              .filter(({ hidden }: any) =>
                hidden && !meta.filePath ? false : true
              )
              .map(
                ({
                  name,
                  icon: Ic,
                  divider,
                  end,
                  onClick,
                  disableClose,
                  danger,
                }: any) => {
                  const handleClick = () => {
                    onClick(options)();

                    if (!disableClose) {
                      handleClose();
                    }
                  };

                  return (
                    <div key={name}>
                      <MenuItem
                        onClick={onClick && handleClick}
                        sx={danger && { color: "error.main" }}
                      >
                        {Ic && (
                          <ListItemIcon sx={danger && { color: "error.main" }}>
                            <Ic fontSize="small" />
                          </ListItemIcon>
                        )}

                        <ListItemText sx={danger && { color: "error.main" }}>
                          {t(name)}
                        </ListItemText>
                        {end && end(options)}
                      </MenuItem>
                      {divider && <Divider sx={{ my: 1 }} />}
                    </div>
                  );
                }
              )}
          </Menu>
        </div>
      )}
    </React.Fragment>
  );
}

const MobileMenu = ({ options }: any) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [menus, setMenus] = useState<any>({});
  const modal = useModal();
  const navigate = useNavigate();
  const { ui, change: changeSettings } = useSettings();
  const { change } = useSubtitles();
  const meta = subtitlesSelectors.getMeta();
  const translated = subtitlesSelectors.getTranslated();
  const { change: changeVideo, ...video } = useVideo();

  const { vivysub } = useVivysub();
  const { enqueueSnackbar } = useSnackbar();

  const props = {
    change,
    modal,
    navigate,
    ui,
    changeSettings,
    changeVideo,
    video,
    subtitles: { translated },
    meta,
    vivysub,
    enqueueSnackbar,
    t,
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <IconButton onClick={() => setOpen(!open)}>
        <MenuOutlined />
      </IconButton>
      <Drawer open={open} onClose={() => setOpen(false)} anchor={"right"}>
        <Box
          sx={{
            minWidth: "50vw",
            mt: "env(safe-area-inset-top)",
          }}
        >
          <List>
            {options.map(
              ({
                divider,
                icon: Icon,
                onClick,
                key,
                name,
                modalKey,
                submenu,
                hideOnClick,
              }: any) => {
                const menu = menus[key]
                  ? submenu
                      .filter(({ hidden }: any) =>
                        hidden && !meta.filePath ? false : true
                      )
                      .map(
                        ({
                          name,
                          onClick,
                          end,
                          danger,
                          icon: Icon,
                          divider,
                          hideOnClick,
                        }: any) => {
                          return (
                            <ListItem
                              key={name}
                              button
                              onClick={(event) => {
                                if (onClick) {
                                  onClick(props)(event);

                                  if (hideOnClick) {
                                    setOpen(false);
                                  }
                                }
                              }}
                              sx={danger && { color: "error.main" }}
                              divider={divider}
                            >
                              {Icon && (
                                <ListItemIcon sx={{ minWidth: 33, ml: 0.5 }}>
                                  <Icon
                                    sx={{
                                      fontSize: 18,
                                      color: danger && "error.main",
                                    }}
                                  />
                                </ListItemIcon>
                              )}
                              <ListItemText
                                secondary={t(name)}
                                secondaryTypographyProps={{
                                  color: "inherit",
                                  fontWeight: 400,
                                }}
                              />
                              {end && end(props)}
                            </ListItem>
                          );
                        }
                      )
                  : [];

                return [
                  <ListItem
                    selected={menus[key]}
                    onClick={(e) => {
                      if (modalKey) {
                        modal.change(modalKey, true);
                      }

                      if (key) {
                        setMenus({
                          ...menus,
                          [key]: !menus[key],
                        });
                      }

                      if (hideOnClick) {
                        setOpen(false);
                      }
                    }}
                    divider={divider}
                    key={key}
                    button
                  >
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Icon sx={{ color: "primary.main" }} />
                    </ListItemIcon>
                    <ListItemText>{t(name)}</ListItemText>
                    {!!submenu?.length && (
                      <ListItemSecondaryAction
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          color: "primary.main",
                          cursor: "pointer",
                        }}
                      >
                        <KeyboardArrowDown />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>,
                  ...menu,
                ];
              }
            )}
          </List>
        </Box>
        <Box sx={{ flex: 1 }} />
        <MenuItem sx={{ py: 2, mb: "env(safe-area-inset-bottom)" }}>
          <Avatar alt="Vivy Sub" src="/broken-image.jpg" />
          <Box sx={{ ml: 2, flex: 1 }}>
            <ListItemText
              primary="account@vivysub.app"
              secondary={<Box>{getDataString(["0MB of 200MB"])}</Box>}
            />
          </Box>
          <IconButton size="small">
            <MoreVert />
          </IconButton>
        </MenuItem>
      </Drawer>
    </Box>
  );
};

const Menubar = () => {
  const theme = useTheme();

  const { change, ui } = useSettings();
  const meta = subtitlesSelectors.getMeta();
  const { t } = useTranslation();
  const { version, vivysub } = useVivysub();
  const { isMobile } = useDevice();
  const isChanged = useMemo(() => {
    return vivysub.isChanged;
  }, [version]);

  const handleOpenInFull = () => {
    const nextValue = !ui.fullScreen;
    change("ui.fullScreen", nextValue);
    if (nextValue) {
      ipcRenderer.send("fullScreen", "true");
    } else {
      ipcRenderer.send("minScreen", "true");
    }
  };

  const filteredOptions = options.filter((n, i) => {
    if (!meta.filePath) {
      return ![1, 2, 3].includes(i);
    }

    return true;
  });

  return (
    <>
      {hasElectron && (
        <Box
          style={
            {
              WebkitUserSelect: "none",
              WebkitAppRegion: "drag",
              flexDirection: "row",
              userSelect: "none",
              position: "fixed",
              backgroundColor: theme.palette.background.paper,
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
              display: "flex",
              justifyContent: "flex-start",
              padding: "6px 20px",

              height: 32,
            } as any
          }
          onDoubleClick={handleOpenInFull}
        >
          {!isMobile && (
            <Typography
              component={"span"}
              variant="caption"
              sx={{
                maxWidth: "calc(100% - 40px)",
                overflow: "hidden",
                marginLeft: isMac ? 7 : undefined,
              }}
            >
              {meta.filePath === "/new-subtitle"
                ? t("ui:header.new_subtitles")
                : meta.filePath || ui.title}
              {isChanged && "*"}
            </Typography>
          )}
        </Box>
      )}

      <div
        id="bar"
        onDoubleClick={(e: any) => {
          if (e.target.id !== "bar") {
            return;
          }

          if (!ui.fullScreen) {
            ipcRenderer.send("fullScreen", "true");
            change("ui.fullScreen", true);
          } else {
            ipcRenderer.send("minScreen", "true");
            change("ui.fullScreen", false);
          }
        }}
        style={
          {
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid " + theme.palette.divider,
            position: "fixed",
            backgroundColor: theme.palette.background.paper,
            top: hasElectron ? 26 : 0,
            paddingTop: "env(safe-area-inset-top)",
            left: 0,
            right: 0,
            zIndex: 1000,
          } as any
        }
      >
        <List
          dense
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
          className="no-drag"
        >
          <ListItem key="logo" sx={{ ml: 0.5, mr: 1 }}>
            <ListItemIcon sx={{ minWidth: 36 }}>
              <img
                alt="logo"
                src="./logo.png"
                width={28}
                height={28}
                style={{ borderRadius: "50%" }}
              />
            </ListItemIcon>
            <Hidden xsDown>
              <ListItemText
                sx={{ ml: 0.5 }}
                primary="VivySub"
                primaryTypographyProps={{ fontWeight: 800 }}
              />{" "}
            </Hidden>
          </ListItem>

          <Hidden mdDown>
            {filteredOptions.map((p) => {
              return <Submenu key={p.name} data={p} />;
            })}
          </Hidden>
        </List>
        <Hidden mdDown>
          {!!meta.filePath && (
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                mr: 1,
              }}
              className="no-drag"
            >
              <Typography sx={{ cursor: "pointer" }}>
                account@vivysub.app
              </Typography>
              <Avatar
                alt="Vivy Sub"
                src="/broken-image.jpg"
                sx={{ width: 28, height: 28, ml: 2, mr: 1 }}
              />
            </Box>
          )}
        </Hidden>
        <Hidden mdUp>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              mr: 1,
            }}
            className="no-drag"
          >
            <MobileMenu options={filteredOptions} />
          </Box>
        </Hidden>
      </div>
    </>
  );
};

export default Menubar;
