/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Box,
  IconButton,
  // Button,
  // DialogActions,
  // Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";

import { useModal } from "../../../core/store/modal";
import { getDataString } from "../../../core/helpers/utils";
import { ArrowBack, FontDownloadOutlined } from "@mui/icons-material";
import { uniqBy } from "lodash";

import { useTranslation } from "react-i18next";
import { useVivysub } from "../../../core/helpers/vivysub";
import { useWindowSize } from "../../../core/hooks/useWindowSize";

function FontsDialog() {
  const modal = useModal();
  const { width } = useWindowSize();
  const theme = useTheme();
  const { version, vivysub } = useVivysub();
  const fonts = useMemo(() => {
    return vivysub.getStyles({ onlyStyles: true });
  }, [version]);

  const { t } = useTranslation();
  const uniqFonts = uniqBy(fonts, "Fontname");

  const handleClose = () => {
    modal.change("fonts", false);
  };

  const isMobile = width < theme.breakpoints.values.md;

  return (
    <div>
      <Dialog open={modal["fonts"]} onClose={handleClose} fullScreen={isMobile}>
        <DialogTitle
          sx={{ mt: window.electron.isMac && isMobile ? 2 : undefined }}
        >
          {isMobile && (
            <IconButton onClick={handleClose} sx={{ mr: 1, ml: -1 }}>
              <ArrowBack />
            </IconButton>
          )}
          {t("modals:fonts.title")}
        </DialogTitle>
        <List sx={{ p: 0, width: isMobile ? "100%" : 500, height: "100%" }}>
          {uniqFonts.map(({ Fontname, Fontsize }: any) => {
            return (
              <ListItem button>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <FontDownloadOutlined />
                </ListItemIcon>
                <ListItemText
                  primary={Fontname}
                  secondary={getDataString([
                    `${Fontsize} ${t("modals:fonts.size")}`,
                  ])}
                />
              </ListItem>
            );
          })}
          {!uniqFonts.length && (
            <Box
              sx={{
                p: 2,
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                py: 6,
                height: "100%",
                minHeight: "100%",
              }}
            >
              <FontDownloadOutlined sx={{ fontSize: "4rem" }} />
              <Typography variant="h6" sx={{ mt: 1 }}>
                Няма шрыфтоў
              </Typography>
            </Box>
          )}
        </List>
        {/* <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button variant={"gradient" as any}>
            {t("modals:fonts.button")}
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default React.memo(FontsDialog);
