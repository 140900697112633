import {
  AttachFileOutlined,
  CloudCircleOutlined,
  DownloadOutlined,
  FilterAltOutlined,
  LanguageOutlined,
  QuestionMarkOutlined,
  RecommendOutlined,
  SearchOutlined,
  SubtitlesOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useMemo, useState } from "react";
import { useDevice } from "../../../core/hooks/useDevice";

const StyledTabs = styled((props: any) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }: any) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "60%",
    backgroundColor: theme.palette.primary.main,
  },
}));

enum CloudTabs {
  all = "all",
  anime = "anime",
  cinema = "cinema",
  others = "others",
}

const Item = () => {
  return (
    <ListItem button sx={{ p: 0, m: 2, overflow: "hidden", borderRadius: 1 }}>
      <ListItemIcon sx={{ mr: 2 }}>
        <Box
          sx={{
            backgroundImage: `url(${"https://i.pinimg.com/originals/22/4a/c5/224ac546d1356b67bd0a6dd92233393f.png"})`,
            width: 88,
            height: 122,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            borderRadius: 1,
          }}
        >
          <Chip label="anime" color="primary" size="small" sx={{ mb: 1 }} />
        </Box>
      </ListItemIcon>
      <ListItemText
        primary={
          <Box>
            <Typography variant="caption">18 minutes ago</Typography>
            <Typography variant="h6">Title name</Typography>
          </Box>
        }
        secondary={
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AttachFileOutlined fontSize="small" color="inherit" />
              <Typography sx={{ ml: 0.5 }}>3 files</Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LanguageOutlined fontSize="small" color="inherit" />
              <Typography sx={{ ml: 0.5 }}>belarusian</Typography>
            </Box>
          </Box>
        }
      />
      <ListItemSecondaryAction>
        <Tooltip title="Спампаваць архіў з субцітрамі">
          <IconButton>
            <DownloadOutlined />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const Recently = () => {
  return (
    <>
      <List sx={{ p: 0 }}>
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
      </List>
    </>
  );
};

const Anime = () => {
  return (
    <>
      <List sx={{ p: 0 }}>
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
      </List>
    </>
  );
};

const EmptyState = () => {
  const { isMobile } = useDevice();

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        height: isMobile ? "calc(100vh - 260px)" : "calc(100vh - 240px)",
        flexDirection: "column",
        minHeight: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <SubtitlesOutlined sx={{ fontSize: "4rem" }} />
      <Typography variant="h6" sx={{ mt: 1 }}>
        Нічога няма
      </Typography>
    </Box>
  );
};

const Cinema = () => {
  return <EmptyState />;
};

const Others = () => {
  return <EmptyState />;
};

const TabContents = {
  [CloudTabs.all]: <Recently />,
  [CloudTabs.anime]: <Anime />,
  [CloudTabs.cinema]: <Cinema />,
  [CloudTabs.others]: <Others />,
};

const Cloud = () => {
  const { isMobile } = useDevice();
  const [tab, setTab] = useState(CloudTabs.all);

  const content = useMemo(() => {
    return TabContents[tab];
  }, [tab]);

  return (
    <Box sx={{ pt: isMobile ? 2 : 0 }}>
      <Paper elevation={0}>
        <TextField
          fullWidth
          placeholder="Search subtitles"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
      </Paper>
      <Paper elevation={1} sx={{ mt: 3, overflow: "hidden" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <StyledTabs
            value={tab}
            onChange={(event: any, value: CloudTabs) => setTab(value)}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{ m: 1, mt: 0 }}
          >
            <Tab label="Новае" value={CloudTabs.all} disableRipple />
            <Tab label="Анімэ" value={CloudTabs.anime} disableRipple />
            <Tab label="Фільмы" value={CloudTabs.cinema} disableRipple />
            <Tab label="Іншае" value={CloudTabs.others} disableRipple />
          </StyledTabs>
        </Box>
        <Divider sx={{ mt: -1 }} />
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: isMobile ? "calc(100vh - 260px)" : "calc(100vh - 240px)",
          }}
        >
          {content}
        </Box>
      </Paper>
      <EmptyState />
      <EmptyState />
    </Box>
  );
};

export default Cloud;
