import "./core/helpers/polyfill";

import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import App from "./Application";
import store, { persistor } from "./core/store/store";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { PersistGate } from "redux-persist/integration/react";
import { HashRouter } from "react-router-dom";
import "./core/localization/i18n";
import * as sw from "./serviceWorker";

import { VivysubProvider, VivySubtitles } from "./core/helpers/vivysub";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBOPewVubTxwtCraBzz6iZ2lJwBoKwLNrI",
  authDomain: "vivysub-6e9dc.firebaseapp.com",
  databaseURL:
    "https://vivysub-6e9dc-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "vivysub-6e9dc",
  storageBucket: "vivysub-6e9dc.appspot.com",
  messagingSenderId: "191162572584",
  appId: "1:191162572584:web:2564224b470ffecfdf7a1d",
  measurementId: "G-KLD70YZM8B",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

const Application = () => {
  const [version, setVersion] = useState(1);
  useEffect(() => {
    const wb = sw.register();

    if (wb) {
      window.wb = wb;

      wb.messageSW({ type: "GET_VERSION" }).then((swVersion) => {
        console.log("Service Worker version:", swVersion);
      });
    }
  }, []);

  const [state, setState] = useState<VivySubtitles | null>(null);

  useEffect(() => {
    const vivysub = new VivySubtitles("", {
      onChange: (store) =>
        window.electron.electronStore.set(
          "currentSubtitle",
          JSON.stringify(store)
        ),
      onNewVersion: (instance) => setVersion(instance.version),
    });

    const vivysubStore = window.electron.electronStore.get("currentSubtitle");

    if (vivysubStore instanceof Promise) {
      vivysubStore.then((value) => {
        if (value) {
          const parsedVivysubStore = JSON.parse(value);

          vivysub.restore(parsedVivysubStore);
        }
      });
    } else {
      if (vivysubStore) {
        const parsedVivysubStore = JSON.parse(vivysubStore);

        vivysub.restore(parsedVivysubStore);
      }
    }

    setState(vivysub);
  }, []);

  const value = useMemo(() => {
    if (!state) {
      return {};
    }

    return { vivysub: state, version };
  }, [version, state]);

  return (
    <React.StrictMode>
      <SnackbarProvider maxSnack={4} autoHideDuration={2000}>
        <VivysubProvider value={value as any}>
          <Provider store={store}>
            <PersistGate loading={<div>Runing...</div>} persistor={persistor}>
              <HashRouter>
                <App />
              </HashRouter>
            </PersistGate>
          </Provider>
        </VivysubProvider>
      </SnackbarProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Application />, document.getElementById("root"));
