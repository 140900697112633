import { useEffect, useRef, useState } from "react";

export function useHover(): any[] {
  const [value, setValue] = useState(false);
  const ref = useRef<any>(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      if (!ref.current) {
        return;
      }

      const node = ref.current;

      node.addEventListener("mouseover", handleMouseOver);
      node.addEventListener("mouseout", handleMouseOut);

      return () => {
        node.removeEventListener("mouseover", handleMouseOver);
        node.removeEventListener("mouseout", handleMouseOut);
      };
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
}
