import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useModal } from "../../../core/store/modal";
import {
  Button,
  DialogActions,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

function SearchAndReplaceDialog() {
  const modal = useModal();
  const { t } = useTranslation();
  const handleClose = () => {
    modal.change("searchAndReplace", false);
  };

  return (
    <div>
      <Dialog open={modal["searchAndReplace"]} onClose={handleClose}>
        <DialogTitle>{t("modals:search_and_replace.title")}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: 500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              label={t("modals:search_and_replace.inputs.search.label")}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={t(
                "modals:search_and_replace.inputs.search.placeholder"
              )}
            />
            <ArrowForward sx={{ mx: 2, mt: 1 }} />
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              label={t("modals:search_and_replace.inputs.replace.label")}
              placeholder={t(
                "modals:search_and_replace.inputs.replace.placeholder"
              )}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Typography component={"span"} sx={{ mt: 2 }} variant="body2">
            {t("modals:search_and_replace.founds", { count: 20 })}
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button variant={"gradient" as any}>{t("ui:common.change")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(SearchAndReplaceDialog);
