/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { Button, DialogActions, Divider } from "@mui/material";

import { useModal } from "../../../core/store/modal";

import { Box } from "@mui/system";
import SelectWithCreation from "../SelectWithCreation";
import { useTranslation } from "react-i18next";
import { useVivysub } from "../../../core/helpers/vivysub";

function EditActorDialog() {
  const modal = useModal();
  const { t } = useTranslation();
  const { vivysub, version } = useVivysub();

  const actors = useMemo(() => {
    return vivysub.getActors();
  }, [version]);

  const [value, setValue] = useState(modal?.props?.Name);

  const handleClose = () => {
    modal.change("edit.actor", false);
    modal.change("props", {});
  };

  const handleSave = () => {
    const id = modal?.props?.id;

    if (id) {
      vivysub.changeEvent(id, {
        Name: value?.title || "",
      });

      handleClose();
    }
  };

  return (
    <div>
      <Dialog open={modal?.edit?.actor} onClose={handleClose}>
        <DialogTitle>{t("modals:edit_actor.title")}</DialogTitle>
        <DialogContent sx={{ minWidth: 460 }}>
          <Box sx={{ mt: 2 }}>
            <SelectWithCreation
              value={value}
              setValue={setValue}
              options={actors}
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose}>{t("ui:common.cancel")}</Button>
          <Button variant={"gradient" as any} onClick={handleSave}>
            {t("ui:common.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditActorDialog;
