/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import { useModal } from "../../../core/store/modal";
import { useTranslation } from "react-i18next";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import { useVideo } from "../../../core/store/video";
import { useDevice } from "../../../core/hooks/useDevice";
import { ArrowBack } from "@mui/icons-material";

function OpenVideoStreamDialog() {
  const [value, setValue] = useState<string>("");
  const modal = useModal();
  const { isMobile } = useDevice();
  const { change } = useVideo();
  const onConfirm = modal?.props || {};
  const { t } = useTranslation();

  const handleClose = () => {
    modal.change("openVideoStream", false);

    if (onConfirm) {
      onConfirm();
    }
  };

  const handleConfirm = () => {
    change("meta.filePath", value);

    handleClose();
  };

  return (
    <div>
      <Dialog
        open={modal["openVideoStream"]}
        onClose={handleClose}
        fullScreen={isMobile}
      >
        <DialogTitle
          sx={{ mt: window.electron.isMac && isMobile ? 2 : undefined }}
        >
          {isMobile && (
            <IconButton onClick={handleClose} sx={{ mr: 1, ml: -1 }}>
              <ArrowBack />
            </IconButton>
          )}
          Адкрыць патокавае відэа
        </DialogTitle>
        <DialogContent sx={{ minWidth: 380 }}>
          <TextField
            sx={{ mt: 1 }}
            label="Потокавае відэа"
            fullWidth
            placeholder="Спасылка на патовакае відэа"
            value={value}
            onChange={({ target }: any) => {
              setValue(target.value);
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose} fullWidth={isMobile}>
            {t("ui:common.no")}
          </Button>
          <Button
            variant={"gradient" as any}
            onClick={handleConfirm}
            fullWidth={isMobile}
          >
            {t("ui:common.yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(OpenVideoStreamDialog);
