import {
  Add,
  ArrowBack,
  CreateNewFolderOutlined,
  DeleteOutlined,
  DownloadOutlined,
  DriveFileRenameOutlineOutlined,
  FilterAltOutlined,
  Folder,
  FolderSharedOutlined,
  ImageOutlined,
  MoreVert,
  PersonOutlined,
  SearchOutlined,
  Settings,
  ShareOutlined,
  SubtitlesOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Fab,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDevice } from "../../../core/hooks/useDevice";

const projects = [
  {
    files: [{}],
    contributors: [{}],
  },
];

const ProjectItemMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 2,
        }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Add fontSize="small" />
          </ListItemIcon>
          <ListItemText>Дадаць файл</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DownloadOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Спампаваць</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <ImageOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Змяніць іконку</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DriveFileRenameOutlineOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Змяніць назву</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <FolderSharedOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Сумесная праца</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <ShareOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Абагуліць</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DeleteOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Выдаліць</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const ProjectItem = ({ project, setSelectedProject }: any) => {
  return (
    <ListItem
      button
      sx={{ p: 1, px: 3 }}
      onClick={() => {
        setSelectedProject(project);
      }}
    >
      <ListItemIcon sx={{ minWidth: 42 }}>
        <Folder />
      </ListItemIcon>
      <ListItemText primary="Project name" secondary="3 files" />
      <ListItemSecondaryAction>
        <ProjectItemMenu />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const FileItemMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 2,
        }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DownloadOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Спампаваць</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DriveFileRenameOutlineOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Змяніць назву</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <ShareOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Абагуліць</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DeleteOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Выдаліць</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const SettingsMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={{ ml: 1 }} onClick={handleClick}>
        <Settings />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 2,
        }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DownloadOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Спампаваць</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DriveFileRenameOutlineOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Змяніць назву</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DeleteOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Выдаліць</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const СontributorMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 2,
        }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DriveFileRenameOutlineOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Змяніць паўнамоцтвы</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DeleteOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Выдаліць з праекта</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const FileItem = ({ file }: any) => {
  return (
    <ListItem button sx={{ p: 1, px: 3 }}>
      <ListItemIcon sx={{ minWidth: 42 }}>
        <SubtitlesOutlined />
      </ListItemIcon>
      <ListItemText primary="File name" secondary="340 lines" />
      <ListItemSecondaryAction>
        <FileItemMenu />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const СontributorItem = ({ contributor }: any) => {
  return (
    <ListItem button sx={{ p: 1, px: 3 }}>
      <ListItemIcon sx={{ minWidth: 42 }}>
        <PersonOutlined />
      </ListItemIcon>
      <ListItemText primary="User name" secondary="editor" />
      <ListItemSecondaryAction>
        <СontributorMenu />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const Filters = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", pl: 1.5 }}>
      <IconButton
        onClick={handleClick}
        sx={{
          width: 55,
          border: `1px solid ${theme.palette.grey[400]}`,
          borderRadius: 1,
        }}
      >
        <FilterAltOutlined />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 2,
        }}
      >
        test
      </Menu>
    </Box>
  );
};

const Projects = () => {
  const { isMobile } = useDevice();
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [tab, setTab] = useState<any>(0);

  return (
    <Box>
      {!isMobile && !selectedProject && (
        <>
          <Button
            startIcon={<CreateNewFolderOutlined />}
            variant={"gradient" as any}
          >
            Новы праект
          </Button>
        </>
      )}
      {selectedProject && (
        <>
          <Button
            startIcon={<ArrowBack />}
            onClick={() => setSelectedProject(null)}
            sx={{ mt: isMobile ? 2 : 0 }}
          >
            Назад
          </Button>
        </>
      )}
      <Paper
        elevation={1}
        sx={{
          mt: 2,
          height: isMobile ? "calc(100vh - 130px)" : "calc(100vh - 160px)",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box sx={{ p: 2, display: "flex" }}>
          {!selectedProject && (
            <>
              <TextField
                placeholder="Search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              <Filters />
            </>
          )}
          {selectedProject && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ListItem sx={{ p: 0, my: -1, px: 1 }}>
                <ListItemIcon sx={{ minWidth: 42 }}>
                  <Folder />
                </ListItemIcon>
                <ListItemText primary="Project name" secondary="3 files" />
              </ListItem>
              <Box sx={{ display: "flex" }}>
                {isMobile ? (
                  <IconButton>
                    <ShareOutlined />
                  </IconButton>
                ) : (
                  <Button startIcon={<ShareOutlined />}>Share</Button>
                )}

                <SettingsMenu />
              </Box>
            </Box>
          )}
        </Box>
        <Divider />
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: isMobile ? "calc(100vh - 162px)" : "calc(100vh - 175px)",
          }}
        >
          {!selectedProject && (
            <List sx={{ p: 0, overflowY: "auto" }}>
              {projects.map((project, index) => {
                return (
                  <ProjectItem
                    project={project}
                    key={index}
                    setSelectedProject={setSelectedProject}
                  />
                );
              })}
            </List>
          )}
          {selectedProject && (
            <>
              <>
                <Tabs
                  value={tab}
                  onChange={(_, nextValue) => setTab(nextValue)}
                  variant="fullWidth"
                >
                  <Tab label="Файлы" value={0} />
                  <Tab label="Нататкі" value={1} />
                  <Tab label="Абагулена" value={2} />
                </Tabs>
                <Divider />
                {tab === 0 && (
                  <>
                    {!!selectedProject?.files?.length && (
                      <List sx={{ p: 0, overflowY: "auto" }}>
                        {selectedProject?.files.map(
                          (file: any, index: number) => {
                            return <FileItem file={file} key={index} />;
                          }
                        )}
                      </List>
                    )}
                    {!selectedProject?.files?.length && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: isMobile
                            ? "calc(100vh - 162px)"
                            : "calc(100vh - 240px)",
                          flexDirection: "column",
                        }}
                      >
                        <SubtitlesOutlined sx={{ fontSize: "4rem" }} />
                        <Typography variant="h6" sx={{ mt: 1 }}>
                          Няма файлаў
                        </Typography>
                        <Button>Стварыць файл</Button>
                        <Button>Загрузіць файл з субцітрамі</Button>
                      </Box>
                    )}
                  </>
                )}
                {tab === 1 && (
                  <Box sx={{ px: 4 }}>
                    <ReactMarkdown>### Markdown notes!</ReactMarkdown>
                  </Box>
                )}
                {tab === 2 && (
                  <>
                    {!selectedProject?.contributors?.length && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: isMobile
                            ? "calc(100vh - 162px)"
                            : "calc(100vh - 240px)",
                          flexDirection: "column",
                        }}
                      >
                        <SubtitlesOutlined sx={{ fontSize: "4rem" }} />
                        <Typography variant="h6" sx={{ mt: 1 }}>
                          Гэты праект ні з кім не абагулены
                        </Typography>
                        <Button>Абагуліць</Button>
                      </Box>
                    )}
                    {!!selectedProject?.contributors?.length && (
                      <List sx={{ p: 0, overflowY: "auto" }}>
                        {selectedProject?.contributors.map(
                          (contributor: any, index: number) => {
                            return (
                              <СontributorItem
                                contributor={contributor}
                                key={index}
                              />
                            );
                          }
                        )}
                      </List>
                    )}
                  </>
                )}
              </>
            </>
          )}
        </Box>
        {isMobile && !selectedProject && (
          <Box sx={{ position: "absolute", right: 20, bottom: 30 }}>
            <Fab color="primary" size="large">
              <Add />
            </Fab>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default Projects;
