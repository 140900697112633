import { useNavigate } from "react-router-dom";

import { useEffect, useMemo, useRef } from "react";

import { useSubtitles } from "../../../core/store/subtitles";
import { useSettings } from "../../../core/store/settings";
import { useVideo } from "../../../core/store/video";
import { importSubtitles } from "../../../core/helpers/files";
import { useSnackbar } from "notistack";
import { useModal } from "../../../core/store/modal";
import { subtitlesSelectors } from "../../../core/store/subtitles/selectors";
import { useVivysub } from "../../../core/helpers/vivysub";

export const useEditorWrapper = () => {
  const modal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { change } = useSubtitles();
  const { vivysub, version } = useVivysub();
  const meta = subtitlesSelectors.getMeta();
  const filters = subtitlesSelectors.getFilters();

  const dialogs = useMemo(() => {
    return vivysub.getFilteredEvents({
      onlyDialogs: true,
      styles: typeof filters.style === "string" ? [filters.style] : [],
      actors: typeof filters.actor === "string" ? [filters.actor] : [],
      groupped: typeof filters.groupBy === "boolean" ? filters.groupBy : true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version, filters]);

  const index = dialogs.findIndex(
    ({ id }: any) => id === meta.currentSubtitleId
  );
  const currentSubtitle = dialogs[index];

  const vid = useVideo();
  const { ui, change: changeSettings } = useSettings();
  const navigate = useNavigate();
  const drawerWidth = ui.drawerWidth;
  const scrollRef = useRef<{
    scrollToItem: (index: number, align: any) => void;
  }>();

  useEffect(() => {
    if (!meta.filePath) {
      navigate("/");
    }
  }, [meta.filePath, navigate]);

  const filtered = dialogs;

  const handleOpenSubtitle = async () => {
    changeSettings("ui.backdrop", true);
    const { content, isVideo, path } = await importSubtitles();
    if (!content && !path) {
      return changeSettings("ui.backdrop", false);
    }

    if (isVideo) {
      modal.change("selectTrack", true);
      modal.change("props", content);
      changeSettings("ui.backdrop", false);
      return;
    }

    if (!content && path) {
      enqueueSnackbar("Файн пашкоджаны", { variant: "error" });
      changeSettings("ui.backdrop", false);
      return;
    }

    vivysub.init(content);
    change("meta.filePath", path);
    changeSettings("ui.backdrop", false);
  };

  const createSubtitle = () => {
    vivysub.add({
      type: "events",
      empty: true,
    });
  };

  useEffect(() => {
    const callback = () => {
      if (scrollRef.current) {
        const index = dialogs.findIndex(
          (dialog: any) => dialog.id === meta.currentSubtitleId
        );

        scrollRef.current.scrollToItem(index, "auto");
      }
    };

    const timout = setTimeout(callback, 450);

    return () => {
      clearTimeout(timout);
    };
  }, [dialogs, scrollRef, meta.currentSubtitleId]);

  return {
    filtered,
    ui,
    vid,
    handleOpenSubtitle,
    drawerWidth,
    createSubtitle,
    scrollRef,
    currentSubtitle,
  };
};
