import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  DialogActions,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@mui/material";
import { useModal } from "../../../core/store/modal";
import {
  Edit,
  InsertDriveFileOutlined,
  SaveAsOutlined,
  Done,
} from "@mui/icons-material";
// import { IOSSwitch } from "../IOSSwitch";
// import assStringify from "ass-stringify";
import { useSnackbar } from "notistack";
import { subtitlesSelectors } from "../../../core/store/subtitles/selectors";
import { useTranslation } from "react-i18next";
import { useVivysub } from "../../../core/helpers/vivysub";

function ExportDialog() {
  const { enqueueSnackbar } = useSnackbar();
  const modal = useModal();
  const { t } = useTranslation();
  const meta = subtitlesSelectors.getMeta();
  const { vivysub } = useVivysub();

  const [editName, setEditName] = useState(true);
  const [fileName, setFileName] = useState(meta.fileName);

  const handleClose = () => {
    modal.change("export", false);
  };

  const handleExport = async () => {
    const result = vivysub.export();
    const options = {
      title: t("modals:export.dialogs.save_as"),
      defaultPath: meta?.filePath?.replace(
        meta.fileName as string,
        fileName as string
      ),
      buttonLabel: t("ui:common.save"),

      filters: [{ name: "subtitles", extensions: [".ass"] }],
    };

    const { filePath } = await window.electron.openDialog(
      "showSaveDialog",
      options
    );

    if (!filePath) {
      handleClose();
      enqueueSnackbar(t("modals:export.messages.error"), {
        variant: "error",
      });

      return;
    }

    window.electron.fs.writeFileSync(filePath, result, "utf-8");

    handleClose();
    enqueueSnackbar(t("modals:export.messages.success"), {
      variant: "success",
    });
  };

  return (
    <div>
      <Dialog open={modal["export"]} onClose={handleClose}>
        <DialogTitle>{t("modals:export.title")}</DialogTitle>
        <DialogContent>
          <List sx={{ p: 0, mx: -2 }}>
            <ListItem>
              <ListItemIcon sx={{ minWidth: 40 }}>
                <InsertDriveFileOutlined />
              </ListItemIcon>
              {editName ? (
                <TextField
                  label={t("modals:export.inputs.file_name.label")}
                  placeholder={t("modals:export.inputs.file_name.placeholder")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  variant="standard"
                  value={fileName}
                  onChange={({ target: { value } }) => {
                    setFileName(value);
                  }}
                />
              ) : (
                <ListItemText
                  primary={t("modals:export.inputs.file_name.label")}
                  secondary={fileName || meta.fileName}
                  secondaryTypographyProps={{
                    sx: {
                      maxWidth: 320,
                    },
                  }}
                />
              )}
              <ListItemSecondaryAction>
                <IconButton onClick={() => setEditName(!editName)}>
                  {editName ? <Done /> : <Edit />}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>

          {/* <List sx={{ p: 0, mx: -2 }}>
            <ListItem>
              <ListItemText
                primary={t("modals:export.options.optimization.title")}
                secondary={t("modals:export.options.optimization.description")}
                secondaryTypographyProps={{
                  sx: {
                    maxWidth: 340,
                  },
                }}
              />
              <ListItemSecondaryAction>
                <IOSSwitch checked />
              </ListItemSecondaryAction>
            </ListItem>
          </List> */}
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button
            variant={"gradient" as any}
            startIcon={<SaveAsOutlined />}
            onClick={handleExport}
            disabled={!fileName || editName}
          >
            {t("modals:export.export")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(ExportDialog);
