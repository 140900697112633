/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  FormControlLabel,
  MenuItem,
  Popover,
  SpeedDialIcon,
  TextField,
  Typography,
} from "@mui/material";
import { IOSSwitch } from "../IOSSwitch";
import { Close } from "@mui/icons-material";
import { useVivysub } from "../../../core/helpers/vivysub";
import { useSubtitles } from "../../../core/store/subtitles";
import { RootState } from "../../../core/store/rootReducer";

function ActionMenu() {
  const [state, setState] = useState({
    actor: -1,
    groupBy: true,
    style: -1,
  });
  const { change } = useSubtitles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { vivysub, version } = useVivysub();

  const styles = useMemo(() => {
    return vivysub.getStyles({ onlyStyles: true });
  }, [version]);

  const actors = useMemo(() => {
    return vivysub.getActors();
  }, [version]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUpdate =
    (type: string) =>
    ({ target: { value } }: any) => {
      if (type === "groupBy") {
        value = !state.groupBy;
      }

      const nextState = {
        ...state,
        [type]: value,
      };
      setState(nextState);

      change((state: RootState["subtitles"]) => ({
        ...state,
        filters: nextState,
      }));
    };

  return (
    <>
      <Box>
        <SpeedDial
          onClick={handleClick}
          ariaLabel="SpeedDial openIcon example"
          sx={{
            position: "absolute",
            bottom: 24,
            right: 24,
          }}
          open={open}
          icon={
            <SpeedDialIcon
              openIcon={<Close />}
              icon={<FilterAltOutlinedIcon />}
            />
          }
        />
        <Popover
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Box sx={{ p: 2, maxWidth: 380 }}>
            <Typography variant="h6" sx={{ mt: -1 }}>
              Фільтры
            </Typography>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                size="small"
                label="Акцёры"
                sx={{ my: 1 }}
                select
                multiline
                value={state.actor}
                defaultValue={-1}
                disabled={actors?.length === 0}
                onChange={handleUpdate("actor")}
              >
                <MenuItem value={-1} key={-1}>
                  Усе акцёры
                </MenuItem>
                {actors.map((name: string) => {
                  return (
                    <MenuItem value={name} key={name}>
                      {name}
                    </MenuItem>
                  );
                })}
              </TextField>
              <TextField
                fullWidth
                size="small"
                label="Стылі"
                sx={{ my: 1 }}
                select
                multiline
                defaultValue={-1}
                disabled={styles?.length === 0}
                value={state.style}
                onChange={handleUpdate("style")}
              >
                <MenuItem value={-1} key={-1}>
                  Усе стылі
                </MenuItem>
                {styles.map(({ id, Name }: any) => {
                  return (
                    <MenuItem value={Name} key={id}>
                      {Name}
                    </MenuItem>
                  );
                })}
              </TextField>
              <FormControlLabel
                control={
                  <IOSSwitch
                    value={state.groupBy}
                    defaultChecked={state.groupBy}
                    onChange={handleUpdate("groupBy")}
                  />
                }
                label="Згрупаваць радкі з аднолькавым змесцівам"
                sx={{ mt: 1 }}
                value={state.groupBy}
                defaultChecked={state.groupBy}
              />
            </Box>
          </Box>
        </Popover>
      </Box>
    </>
  );
}

export default ActionMenu;
