import {
  CloseOutlined,
  SpellcheckOutlined,
  ExpandMore,
} from "@mui/icons-material";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Drawer,
  IconButton,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import {
  duractionInSeconds,
  getDataString,
  removeTags,
} from "../../../core/helpers/utils";
import { useDebounce } from "../../../core/hooks/useDebounce";
import { usePrevValue } from "../../../core/hooks/usePrevValue";
import { useSettings } from "../../../core/store/settings";
import { useSubtitles } from "../../../core/store/subtitles";

import { subtitlesSelectors } from "../../../core/store/subtitles/selectors";
import { useHotkeys } from "react-hotkeys-hook";
import { RootState } from "../../../core/store/rootReducer";
import { useVivysub } from "../../../core/helpers/vivysub";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Popout from "react-popout";
import { useWindowSize } from "../../../core/hooks/useWindowSize";
import { hasElectron } from "../../../core/helpers/polyfill";

const Dictionaries = ({ state, handleChange, ui, isDesktop }: any) => {
  const [pip, setPip] = useState(false);

  const iframe = (
    <iframe
      src={`https://dictionaries.vivy.app/?embed=1&mode=${ui.mode}`}
      width={isDesktop ? ui.drawerWidth : "100%"}
      title="Dictionaties"
      height="100%"
      style={{ border: "0px" }}
    />
  );

  if (pip) {
    return (
      <Popout
        url={`https://dictionaries.vivy.app/?embed=1&mode=${ui.mode}`}
        onClosing={() => {
          handleChange("dictionaries")(null, false);
          setPip(false);
        }}
        title="Слоўнікі"
        options={{ width: 360, height: 620 }}
      />
    );
  }

  if (!ui.dictionaries) {
    return null;
  }

  return (
    <Accordion
      expanded={state === "dictionaries"}
      onChange={handleChange("dictionaries")}
      disableGutters
      elevation={0}
      sx={{ borderRadius: "0px!important" }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <img
          src="https://dictionaries.vivy.app/logo.svg"
          width={24}
          alt="dictionaries"
        />
        <Typography component={"span"} sx={{ ml: 1, flex: 1 }}>
          Cлоўнікі
        </Typography>
        {isDesktop && (
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              setPip(true);
            }}
            size="small"
            sx={{ mr: 0.5 }}
          >
            <OpenInNewIcon sx={{ fontSize: "1rem" }} />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 0,
          maxWidth: !isDesktop ? undefined : ui.drawerWidth,
          overflow: "hidden",
        }}
      >
        <Box sx={{ height: 420 }}>{iframe}</Box>
      </AccordionDetails>
    </Accordion>
  );
};

const Glossaries = ({ state, handleChange, ui, isDesktop }: any) => {
  const [pip, setPip] = useState(false);

  if (pip) {
    return (
      <Popout
        url={`https://glossaries.vivy.app/?embed=1&mode=${ui.mode}`}
        onClosing={() => {
          handleChange("tm")(null, false);
          setPip(false);
        }}
        title="Гласарыі"
        options={{ width: 360, height: 620 }}
      />
    );
  }

  if (!ui.tm) {
    return null;
  }

  return (
    <Accordion
      expanded={state === "tm"}
      onChange={handleChange("tm")}
      disableGutters
      elevation={0}
      sx={{ borderRadius: "0px!important" }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <img
          src="https://glossaries.vivy.app/logo.svg"
          width={24}
          alt="glossaries"
        />
        <Typography component={"span"} sx={{ ml: 1, flex: 1 }}>
          Гласарыі
        </Typography>
        {isDesktop && (
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              setPip(true);
            }}
            size="small"
            sx={{ mr: 0.5 }}
          >
            <OpenInNewIcon sx={{ fontSize: "1rem" }} />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 0,
          maxWidth: !isDesktop ? undefined : ui.drawerWidth,
          overflow: "hidden",
        }}
      >
        <Box sx={{ height: 420 }}>
          <iframe
            src={`https://glossaries.vivy.app/?embed=1&mode=${ui.mode}`}
            width={isDesktop ? ui.drawerWidth : "100%"}
            height="100%"
            title="Glossaries"
            style={{ border: "0px" }}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const Translation = React.memo(
  ({
    currentSubtitle,
    translate,
    ui,
    state,
    handleChange,
    translatedSubtitle,
    isDesktop,
    handleTranslate,
    dialogs,
    index,
    changeSub,
  }: any) => {
    const enterRef = useHotkeys(
      "enter",
      (event) => {
        event.preventDefault();
        const nextSubtitle = dialogs[index + 1];

        if (nextSubtitle) {
          changeSub((state: RootState["subtitles"]) => ({
            ...state,
            meta: {
              ...state.meta,
              currentSubtitleId: nextSubtitle.id,
            },
            selected: { [nextSubtitle.id]: true },
            currentSubtitle: nextSubtitle,
          }));
        }
      },
      {
        enableOnTags: ["TEXTAREA"],
      }
    );

    const defaultText =
      currentSubtitle.OriginalText || currentSubtitle.Text || "Пусты радок";

    return (
      <>
        <Box
          sx={{
            justifyContent: "flex-start",
            alignItems: "flex-end",
            display: "flex",
            flexDirection: "column",
            p: 2,
            flex: 1,
          }}
        >
          <TextField
            fullWidth
            label="Арыгінал"
            multiline
            variant="standard"
            value={
              currentSubtitle.merged ? removeTags(defaultText) : defaultText
            }
            unselectable="on"
            InputProps={{ disableUnderline: true }}
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            fullWidth
            variant="standard"
            label="Пераклад"
            placeholder="Уводзьце тэкст тут..."
            multiline
            minRows={6}
            sx={{ border: 0 }}
            value={
              translate !== null || translate !== undefined
                ? translate
                : defaultText
            }
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ shrink: true }}
            onChange={handleTranslate}
            inputRef={enterRef as any}
          />
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Divider />
          {ui.spellchecker && (
            <Accordion
              expanded={state === "spellChecker"}
              disableGutters
              elevation={0}
              onChange={handleChange("spellChecker")}
              sx={{ borderRadius: "0px!important" }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <SpellcheckOutlined />
                <Typography component={"span"} sx={{ ml: 1 }}>
                  Памылкі
                </Typography>
              </AccordionSummary>
              <AccordionDetails></AccordionDetails>
            </Accordion>
          )}
          <Dictionaries
            ui={ui}
            state={state}
            handleChange={handleChange}
            isDesktop={isDesktop}
          />
          <Glossaries
            ui={ui}
            state={state}
            handleChange={handleChange}
            isDesktop={isDesktop}
          />
        </Box>
      </>
    );
  }
);

export const DrawerEditor = () => {
  const { ui, change } = useSettings();
  const { change: changeSub } = useSubtitles();
  const { width } = useWindowSize();
  const meta = subtitlesSelectors.getMeta();
  const theme = useTheme();
  const [state, setState] = useState<string>("");
  const [translate, setTranslate] = useState<string | null>(null);
  const { vivysub, version } = useVivysub();

  const filters = subtitlesSelectors.getFilters();

  const dialogs = useMemo(() => {
    return vivysub.getFilteredEvents({
      onlyDialogs: true,
      styles: typeof filters.style === "string" ? [filters.style] : [],
      actors: typeof filters.actor === "string" ? [filters.actor] : [],
      groupped: typeof filters.groupBy === "boolean" ? filters.groupBy : true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version, filters]);
  const index = dialogs.findIndex(
    ({ id }: any) => id === meta.currentSubtitleId
  );
  const currentSubtitle = dialogs[index];

  const handleChange = (key: string) => (_: any, value: any) => {
    setState(value ? key : "");
  };

  const handleTranslate = ({ target: { value } }: any) => {
    setTranslate(value);
  };

  useEffect(() => {
    if (currentSubtitle) {
      setTranslate(currentSubtitle.Text);
    }
  }, [currentSubtitle]);

  const debouncedTranslation = useDebounce(translate, 300);
  const prevTranslation = usePrevValue<string>(
    debouncedTranslation,
    debouncedTranslation
  );

  useEffect(() => {
    if (
      prevTranslation !== debouncedTranslation &&
      currentSubtitle?.id &&
      currentSubtitle?.Text === prevTranslation
    ) {
      if (currentSubtitle.merged) {
        vivysub.changeEvents(currentSubtitle.nested, {
          noMerge: false,
          Text: (text: string) => {
            const clearedValue = removeTags(text);

            const replacedText = text.replace(
              clearedValue,
              debouncedTranslation
            );

            return replacedText;
          },
        });
      } else {
        vivysub.changeEvent(currentSubtitle.id, {
          Text: debouncedTranslation,
        });
      }
    }
  }, [
    changeSub,
    currentSubtitle?.id,
    currentSubtitle?.Text,
    currentSubtitle?.merged,
    currentSubtitle?.nested,
    debouncedTranslation,
    prevTranslation,
    version,
    vivysub,
  ]);

  if (!currentSubtitle) {
    return null;
  }

  const Text = currentSubtitle.Text;

  const duractionSecond = duractionInSeconds(
    currentSubtitle.Start,
    currentSubtitle.End
  );

  const cps = +Number(removeTags(Text).length / duractionSecond).toFixed();

  const isDesktop = width > theme.breakpoints.values.md;

  return (
    <Drawer
      anchor={"right"}
      open={ui.editDrawer}
      variant={"persistent"}
      PaperProps={{
        sx: {
          top: hasElectron ? 79 : "calc(env(safe-area-inset-top) + 53px)",
          maxWidth: isDesktop ? ui.drawerWidth : "100vw",
          width: isDesktop ? ui.drawerWidth : "100vw",
          height: hasElectron
            ? "calc(100vh - 79px)"
            : "calc(100vh - calc(env(safe-area-inset-bottom) + env(safe-area-inset-top) + 53px))",
        },
      }}
    >
      <Box
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          p: 2,
          py: 1,
        }}
      >
        <ListItemText
          primary={<>Радок #{index + 1}</>}
          secondary={
            <>
              {getDataString([
                `${Text.length} сімвалаў`,
                `${Text.split(" ").length} слоў`,
                `${cps} cps`,
              ])}{" "}
            </>
          }
        />

        <IconButton onClick={() => change("ui.editDrawer", false)}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <Divider />
      <Translation
        {...{
          changeSub,
          currentSubtitle,
          dialogs,
          handleChange,
          handleTranslate,
          index,
          state,
          translate,
          ui,
          isDesktop,
        }}
      />
    </Drawer>
  );
};
