import assParser from "ass-parser";
import assStringify from "ass-stringify";
import { diff } from "deep-object-diff";
import _ from "lodash";
import React, { useContext } from "react";
import { v4 } from "uuid";
import { removeTags } from "./utils";

type Entity = {
  [key: string]: any;
};

type HistoryEntity = {
  id: string;
  type: string;
  diff?: Entity;
  entity?: Entity;
  entityType?: "events" | "styles";
};

type IGetFilteredEvents = {
  onlyDialogs: boolean;
  actors?: string[];
  styles?: string[];
  groupped?: boolean;
};

interface IVivySubtitles {
  info: Entity;
  styles: Entity;
  events: Entity;
  changes: HistoryEntity[];
}

interface VivySubtitlesOptions {
  onChange?: (data: IVivySubtitles) => void;
  onNewVersion?: (data: VivySubtitles) => void;
}

interface IAddNewEntity {
  type: "events" | "styles";
  empty: boolean;
  value?: Entity;
}

const detailedDiff = (original: any, nextValue: any) => {
  const next = diff(_.cloneDeep(original), _.cloneDeep(nextValue));
  const prev = diff(_.cloneDeep(nextValue), _.cloneDeep(original));

  return {
    prev,
    next,
  };
};

const EmptyTemplates = {
  events: {
    value: {
      Layer: "0",
      Start: "0:00:00.00",
      End: "0:00:01.00",
      Style: "Default",
      Name: "",
      MarginL: "0",
      MarginR: "0",
      MarginV: "0",
      Effect: "",
      Text: "",
      noMerge: true,
    },
    key: "Dialogue",
  },
  styles: {
    Name: "Default",
    Fontname: "Montserrat Bold",
    Fontsize: "72",
    PrimaryColour: "&H00FFFFFF",
    SecondaryColour: "&H000000FF",
    OutlineColour: "&H00000000",
    BackColour: "&H00000000",
    Bold: "0",
    Italic: "0",
    Underline: "0",
    StrikeOut: "0",
    ScaleX: "100",
    ScaleY: "115",
    Spacing: "0.7",
    Angle: "0",
    BorderStyle: "1",
    Outline: "3",
    Shadow: "0",
    Alignment: "2",
    MarginL: "3",
    MarginR: "3",
    MarginV: "45",
    Encoding: "0",
  },
};

export class VivySubtitles implements IVivySubtitles {
  info: Entity = {};
  styles: Entity = {};
  events: Entity = {};
  changes: HistoryEntity[] = [];
  onChange: VivySubtitlesOptions["onChange"];
  onNewVersion: VivySubtitlesOptions["onNewVersion"];
  version: number = 1;
  _isChanged: boolean = false;
  _cache: Entity = {};
  _historyIndex: null | number = null;

  constructor(subtitles: string, options: VivySubtitlesOptions = {}) {
    this._init(subtitles, options);
  }

  private _init(subtitles: string, options: VivySubtitlesOptions = {}) {
    const parsedSubtitles = assParser(subtitles);

    const info = this._getSubtitleSection("Info", parsedSubtitles);
    const styles = this._getSubtitleSection("Styles", parsedSubtitles);
    const events = this._getSubtitleSection("Events", parsedSubtitles);

    this.info = this._objectify(info);
    this.styles = this._objectify(styles);
    this.events = this._objectify(events);

    if (options.onChange) {
      this.onChange = options.onChange;
    }

    if (options.onNewVersion) {
      this.onNewVersion = options.onNewVersion;
    }
  }

  private _getSubtitleSection(section: string, subtitles: Entity[]) {
    return subtitles.find((s) => s.section.includes(section))?.body || [];
  }

  private _objectify(body: any[]) {
    return body.reduce((acc, value) => {
      const id = v4();
      acc[id] = value;
      return acc;
    }, {});
  }

  private _arrayify(obj: Entity, section: string) {
    const body = [];
    const keys = Object.keys(obj);

    for (const key of keys) {
      const value = obj[key];

      body.push(value);
    }

    return { section, body };
  }

  private _toFlatArray(obj: Entity) {
    const result = [];
    const keys = Object.keys(obj);

    for (const key of keys) {
      const value = obj[key];

      const content =
        typeof value.value === "string" ? { value: value.value } : value.value;

      result.push({ id: key, ...content, key: value.key });
    }

    return result;
  }

  getInfo(): Entity[] {
    const cacheValue = this._cache["getInfo"];
    if (cacheValue) {
      return cacheValue;
    }

    const result = this._toFlatArray(this.info);

    this._cache["getInfo"] = result;

    return result;
  }

  getEvents(): Entity[] {
    const cacheValue = this._cache["events"];
    if (cacheValue) {
      return cacheValue;
    }

    const result = this._toFlatArray(this.events);

    this._cache["events"] = result;

    return result;
  }

  getFilteredEvents(options: IGetFilteredEvents) {
    const { onlyDialogs, actors = [], styles = [], groupped } = options;
    const cacheValue =
      this._cache["getFilteredEvents" + JSON.stringify(options)];
    if (cacheValue) {
      return cacheValue;
    }

    let events = this.getEvents();
    if (onlyDialogs) {
      events = events.filter(({ key }) => key === "Dialogue");
    }

    if (actors.length) {
      events = events.filter(({ Name }) => actors.includes(Name));
    }

    if (styles.length) {
      events = events.filter(({ Style }) => styles.includes(Style));
    }

    if (groupped) {
      const grouppedEvents = _.groupBy(
        events.map((e) => {
          return {
            ...e,
            groupKey: e.noMerge ? e.id : removeTags(e.Text),
          };
        }),
        "groupKey"
      );

      const targetEvents = Object.entries(grouppedEvents).reduce(
        (acc: any, [key, value]: any) => {
          const [first] = value;
          const isMerged = value.length > 1;
          const target = {
            ...first,
            Text: removeTags(first.Text),
            merged: isMerged,
            nested: isMerged ? value.map(({ id }: any) => id) : [],
          };

          delete target.groupKey;

          acc.push(target as unknown as any);

          return acc;
        },
        []
      );

      events = targetEvents;
    }

    events = _.orderBy(events, "Start");

    this._cache["getFilteredEvents" + JSON.stringify(options)] = events;

    return events;
  }

  getStyles(options: { onlyStyles: boolean } = { onlyStyles: true }) {
    const cacheValue = this._cache["styles" + JSON.stringify(options)];
    if (cacheValue) {
      return cacheValue;
    }

    let result = this._toFlatArray(this.styles);

    if (options.onlyStyles) {
      result = result.filter(({ key }) => key === "Style");
    }

    this._cache["styles" + JSON.stringify(options)] = result;

    return result;
  }

  getChangeHistory() {
    return this.changes;
  }

  getActors() {
    const cacheValue = this._cache["getActors"];
    if (cacheValue) {
      return cacheValue;
    }

    const events = this.getEvents();
    const actors = _.uniqBy(events, "Name");
    const result = actors.map(({ Name }) => Name).filter(Boolean);

    this._cache["getActors"] = result;

    return result;
  }

  getSubtitles() {
    const cacheValue = this._cache["getSubtitles"];
    if (cacheValue) {
      return cacheValue;
    }

    const info = this._arrayify(this.info, "Script Info");
    const styles = this._arrayify(this.styles, "V4+ Styles");
    const events = this._arrayify(this.events, "Events");

    const result = [info, styles, events];

    this._cache["getSubtitles"] = result;

    return result;
  }

  export() {
    const subtitles = this.getSubtitles();

    return assStringify(subtitles);
  }

  search(
    query: string,
    options: IGetFilteredEvents = {
      groupped: true,
      onlyDialogs: true,
      styles: [],
      actors: [],
    }
  ) {
    const events = this.getFilteredEvents(options);

    const result = events.filter((event: any) => {
      return event?.Text?.toLowerCase()?.includes(query?.toLowerCase());
    });

    return result;
  }

  findAndReplace(
    query: string,
    replacament: string,
    options: IGetFilteredEvents = {
      groupped: true,
      onlyDialogs: true,
      styles: [],
      actors: [],
    }
  ) {
    const events = this.search(query, options);
    const ids = events.map(({ id }: any) => id);

    this.changeEvents(ids, {
      Text: (text: string) => text.replaceAll(query, replacament),
    });
  }

  addChanges(type: string, id: string, prev: Entity, next: Entity) {
    this.changes.push({
      type,
      id,
      diff: detailedDiff(prev, next),
    });

    this._onChange();
  }

  changeEvent(id: string, payload: any = {}) {
    const obj = { ...payload };
    const targetEvent = this.events[id];
    if (!targetEvent) {
      throw new Error("No event with such ID");
    }

    if (typeof obj.Text === "function") {
      const originalText = targetEvent.value.Text;
      const nextValue = obj?.Text(originalText);

      obj.Text = nextValue;
    }

    const nextValue = {
      ...targetEvent,
      value: {
        ...targetEvent.value,
        noMerge: true,
        ...obj,
      },
    };

    if ("Text" in obj && !targetEvent.value?.OriginalText) {
      nextValue.value.OriginalText = targetEvent.value.Text;
    }

    this.events[id] = nextValue;

    this.addChanges("events", id, this.events[id], nextValue);

    return this.events;
  }

  changeEvents(ids: string[], payload: any = {}) {
    for (const id of ids) {
      const obj = _.clone(payload);
      const targetEvent = this.events[id];
      if (!targetEvent) {
        continue;
      }

      if (typeof obj.Text === "function") {
        const originalText = targetEvent.value.Text;
        const nextValue = obj?.Text(originalText);

        obj.Text = nextValue;
      }

      const nextValue = {
        ...targetEvent,
        value: {
          ...targetEvent.value,
          noMerge: true,
          ...obj,
        },
      };

      if ("Text" in obj && !targetEvent.value?.OriginalText) {
        nextValue.value.OriginalText = targetEvent.value.Text;
      }

      this.events[id] = nextValue;
    }

    this.addChanges("find_and_replace", "-1", {}, {});
  }

  removeEvent(id: string) {
    this.changes.push({
      id,
      type: "remove_event",
      entity: _.clone(this.events[id]),
    });

    delete this.events[id];

    this._nextVersion();

    return this.events;
  }

  removeEvents(ids: string[]) {
    for (const id of ids) {
      this.removeEvent(id);
    }
  }

  add(options: IAddNewEntity) {
    const { type, empty, value } = options;
    const id = v4();

    let entity;
    if (empty && !value) {
      entity = EmptyTemplates[type];
    } else {
      entity = value;
    }

    this[type][id] = entity;

    this.changes.push({
      id,
      type: "add",
      entity,
      entityType: type,
    });

    this._nextVersion();

    return this[type][id];
  }

  removeStyle(id: string) {
    this.changes.push({
      id,
      type: "remove_style",
      entity: _.clone(this.styles[id]),
    });

    delete this.styles[id];

    this._nextVersion();

    return this.styles;
  }

  removeInfo(id: string) {
    this.changes.push({
      id,
      type: "remove_info",
      entity: _.clone(this.info[id]),
    });

    delete this.info[id];

    this._nextVersion();

    return this.info;
  }

  changeInfo(id: string, obj = {}) {
    if (!this.info[id]) {
      throw new Error("No info with such ID");
    }

    const nextValue = {
      ...this.info[id],
      value: {
        ...this.info[id],
        ...obj,
      },
    };

    this.addChanges("info", id, this.info[id], nextValue);

    this.info[id] = nextValue;

    return this.events;
  }

  changeStyles(id: string, obj: any = {}) {
    if (!this.styles[id]) {
      throw new Error("No style with such ID");
    }

    const nextValue = {
      ...this.styles[id],
      value: {
        ...this.styles[id].value,
        ...obj,
      },
    };

    if ("Name" in obj) {
      const Name: string = this.styles[id].value.Name;
      const ids = this.getFilteredEvents({
        styles: [Name],
        onlyDialogs: true,
      }).map(({ id }: any) => id);

      this.changeEvents(ids, {
        Style: obj.Name,
      });
    }

    this.styles[id] = nextValue;

    this.addChanges("styles", id, this.styles[id], nextValue);

    return this.styles;
  }

  private _nextVersion() {
    this.version = this.version + 1;
    this._cache = {};

    if (this.onNewVersion) {
      this.onNewVersion(this);
    }
  }

  reset() {
    this.info = {};
    this.styles = {};
    this.events = {};
    this.changes = [];
    this._isChanged = false;
    this._nextVersion();
  }

  init(subtitles: string, options: VivySubtitlesOptions = {}) {
    this._init(subtitles, options);
    this._nextVersion();
  }

  toStore(): IVivySubtitles {
    const store = {
      info: this.info,
      styles: this.styles,
      events: this.events,
      changes: this.changes,
      _isChanged: this._isChanged,
    };

    return store;
  }

  restore(store: any) {
    this.info = store.info;
    this.styles = store.styles;
    this.events = store.events;
    this.changes = store.changes;
    this._isChanged = store._isChanged;
    this._nextVersion();
  }

  private _onChange() {
    if (!this._isChanged) {
      this._isChanged = true;
    }

    if (this._historyIndex !== null) {
      this._historyIndex = null;
    }

    this._nextVersion();

    if (this.onChange) {
      this.onChange(this.toStore());
    }
  }

  undo() {
    const history = this.changes;

    if (this._historyIndex === null) {
      this._historyIndex = history.length;
    }

    if (this._historyIndex < 0 || !history.length) {
      return;
    }

    this._historyIndex = this._historyIndex - 1;

    const point = history[this._historyIndex];

    if (point) {
      const { type, id, diff, entityType, entity } = point;

      switch (type) {
        case "events": {
          if (diff) {
            const prevValue = _.cloneDeep(diff.prev);
            const currentValue = _.cloneDeep(this.events[id]);
            const restoredEvent = _.merge(currentValue, prevValue);

            this.events[id] = restoredEvent;
          }
          break;
        }
        case "add": {
          if (entityType) {
            delete (this as any)[entityType][id];
          }
          break;
        }

        case "remove_event": {
          this.events[id] = entity;

          break;
        }
      }

      this._nextVersion();
    }
  }

  redo() {
    if (this._historyIndex === null || !this.changes.length) {
      return;
    }

    const history = this.changes;

    const point = history[this._historyIndex];

    if (point) {
      const { type, id, diff, entity, entityType } = point;

      switch (type) {
        case "events": {
          if (diff) {
            if (!this.events[id]) {
              const deletedEvent = history.find((historyRecord) => {
                return (
                  historyRecord.id === id &&
                  ["add", "remove_event"].includes(historyRecord.type)
                );
              });

              if (deletedEvent) {
                this.events[id] = deletedEvent.entity;
              }
            }

            const prevValue = _.cloneDeep(diff.next);
            const currentValue = _.cloneDeep(this.events[id]);
            const restoredEvent = _.merge(currentValue, prevValue);

            this.events[id] = restoredEvent;
          }
          break;
        }
        case "add": {
          if (entityType) {
            (this as any)[entityType][id] = entity;
          }
          break;
        }
        case "remove_event": {
          delete this.events[id];

          break;
        }
      }

      this._historyIndex = this._historyIndex + 1;
      this._nextVersion();
    }
  }

  setIsChanged(value: boolean) {
    this._isChanged = value;
    this._nextVersion();
  }

  get isChanged() {
    return this._isChanged;
  }
}

const VivysubContext = React.createContext<{
  vivysub: VivySubtitles;
  version: number;
} | null>(null);
export const useVivysub = () =>
  useContext(VivysubContext) as {
    vivysub: VivySubtitles;
    version: number;
  };
export const VivysubProvider = VivysubContext.Provider;
