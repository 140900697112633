/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useModal } from "../../../core/store/modal";
import {
  ArrowBack,
  StyleOutlined,
  SubtitlesOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useVivysub } from "../../../core/helpers/vivysub";
import { useDevice } from "../../../core/hooks/useDevice";

function PropertiesDialog() {
  const modal = useModal();
  const { t } = useTranslation();
  const { version, vivysub } = useVivysub();
  const { isMobile } = useDevice();

  const dialogs = useMemo(() => {
    return vivysub.getFilteredEvents({ onlyDialogs: true });
  }, [version]);

  const styles = useMemo(() => {
    return vivysub.getStyles();
  }, [version]);

  const info = useMemo(() => {
    return vivysub.getInfo();
  }, [version]);

  const handleClose = () => {
    modal.change("properties", false);
  };

  const comments = info.filter(({ type }) => type === "comment");

  return (
    <div>
      <Dialog
        open={modal["properties"]}
        onClose={handleClose}
        fullScreen={isMobile}
      >
        <DialogTitle
          sx={{ mt: window.electron.isMac && isMobile ? 2 : undefined }}
        >
          {isMobile && (
            <IconButton onClick={handleClose} sx={{ mr: 1, ml: -1 }}>
              <ArrowBack />
            </IconButton>
          )}
          {t("modals:properties.title")}
        </DialogTitle>
        <DialogContent sx={{ minWidth: 360 }}>
          <Divider sx={{ mb: 2 }} />
          <List sx={{ p: 0, display: "flex" }}>
            <ListItem sx={{ my: -2 }} button>
              <ListItemIcon sx={{ minWidth: 40 }}>
                <SubtitlesOutlined />
              </ListItemIcon>
              <ListItemText
                primary={t("modals:properties.lines")}
                secondary={dialogs.length}
              />
            </ListItem>
            <ListItem sx={{ my: -2 }} button>
              <ListItemIcon sx={{ minWidth: 40 }}>
                <StyleOutlined />
              </ListItemIcon>
              <ListItemText
                primary={t("modals:properties.styles")}
                secondary={styles.length}
              />
            </ListItem>
          </List>
          <Divider sx={{ my: 2 }} />
          <List sx={{ p: 0, m: -2 }}>
            {info
              .filter(({ type }) => type !== "comment")
              .map(({ key, value }) => {
                return (
                  <ListItem key={key} sx={{ my: -2 }}>
                    <ListItemText primary={key} secondary={value} />
                  </ListItem>
                );
              })}
          </List>

          {!!comments.length && (
            <>
              <Divider sx={{ my: 2 }} />
              {comments.map(({ value }) => {
                return (
                  <ListItem key={value} sx={{ m: -2 }}>
                    <Typography component={"span"} variant="body2">
                      {value}
                    </Typography>
                  </ListItem>
                );
              })}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default React.memo(PropertiesDialog);
