import { useSelector } from "react-redux";

export function createSelectorHook<T>(mapper: any) {
  function SelectorHook(...props: any): T {
    const result = useSelector(mapper(...props));

    return result as T;
  }

  return SelectorHook;
}
