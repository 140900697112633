import { useTheme } from "@mui/system";
import { useWindowSize } from "./useWindowSize";

export const useDevice = () => {
  const { width } = useWindowSize();
  const theme = useTheme();
  const isMobile = width < theme.breakpoints.values.md;
  const isDesktop = width > theme.breakpoints.values.md;

  return { isMobile, isDesktop };
};
