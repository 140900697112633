/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Button,
  DialogActions,
  Divider,
  MenuItem,
  TextField,
} from "@mui/material";

import { useModal } from "../../../core/store/modal";

import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useVivysub } from "../../../core/helpers/vivysub";

function EditStyleDialog() {
  const modal = useModal();
  const { t } = useTranslation();

  const { vivysub, version } = useVivysub();

  const styles = useMemo(() => {
    return vivysub.getStyles({ onlyStyles: true });
  }, [version]);

  const [value, setValue] = useState(modal?.props?.Style || -1);

  const handleClose = () => {
    modal.change("edit.style", false);
    modal.change("props", {});
  };

  const handleSave = () => {
    const id = modal?.props?.id;

    if (id) {
      vivysub.changeEvent(id, {
        Style: value,
      });

      handleClose();
    }
  };

  return (
    <div>
      <Dialog open={modal?.edit?.style} onClose={handleClose}>
        <DialogTitle>{t("modals:edit_style.title")}</DialogTitle>
        <DialogContent sx={{ minWidth: 460 }}>
          <Box sx={{ mt: 2 }}>
            <TextField
              select
              autoComplete="on"
              fullWidth
              value={value}
              onChange={({ target: { value } }) => setValue(value)}
            >
              <MenuItem key={-1} value={-1} disabled>
                {t("modals:edit_style.select")}
              </MenuItem>
              {styles.map(({ Name }: any) => {
                return (
                  <MenuItem key={Name} value={Name}>
                    {Name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose}>{t("ui:common.cancel")}</Button>
          <Button variant={"gradient" as any} onClick={handleSave}>
            {t("ui:common.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditStyleDialog;
