import * as React from "react";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCopy from "@mui/icons-material/ContentCopy";
import { IconButton, Menu } from "@mui/material";
import {
  AssignmentOutlined,
  ContentCut,
  DeleteOutlined,
  MoreVert,
  PersonOutlined,
  StyleOutlined,
  SubtitlesOutlined,
  TimelapseOutlined,
} from "@mui/icons-material";
import { useModal } from "../../../core/store/modal";
import { EntityDialogValue } from "../../../core/types/subtitles";

import { subtitlesSelectors } from "../../../core/store/subtitles/selectors";
import { useVivysub } from "../../../core/helpers/vivysub";
import { msSubtitle, timeToSeconds } from "../../../core/helpers/utils";

export interface ISubMenu {
  anchorEl: any;
  setAnchorEl: (value: any) => void;
  subtitle: EntityDialogValue;
}

function SubMenu({ anchorEl, setAnchorEl, subtitle }: ISubMenu) {
  const open = Boolean(anchorEl);
  const modal = useModal();
  const { vivysub } = useVivysub();
  const selected = subtitlesSelectors.getSelection();

  const handleClick = (event: any) => {
    setAnchorEl({
      left: event.clientX,
      top: event.clientY,
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectedItems = Object.entries(selected).filter(
    ([key, value]) => value
  )?.length;

  const multipleSelection = selectedItems > 1;

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={open}
        onClose={handleClose}
        anchorPosition={{
          left: anchorEl?.left,
          top: anchorEl?.top,
        }}
        anchorReference="anchorPosition"
        MenuListProps={{
          dense: false,
        }}
        PaperProps={{
          elevation: 2,
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            vivysub.add({
              type: "events",
              empty: false,
              value: {
                value: {
                  Layer: "0",
                  Start: subtitle.End,
                  End: msSubtitle(timeToSeconds(subtitle.End) * 1000 + 250),
                  Style: "Default",
                  Name: "",
                  MarginL: "0",
                  MarginR: "0",
                  MarginV: "0",
                  Effect: "",
                  Text: "",
                  noMerge: true,
                },
                key: "Dialogue",
              },
            });
          }}
        >
          <ListItemIcon>
            <SubtitlesOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Дадаць радок</ListItemText>
        </MenuItem>
        {!multipleSelection && (
          <div>
            <Divider sx={{ my: 1 }} />
            <MenuItem
              onClick={() => {
                modal.change("edit.timing", true);
                modal.change("props", subtitle);
                handleClose();
              }}
            >
              <ListItemIcon>
                <TimelapseOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText>Змяніць таймінг</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                modal.change("edit.style", true);
                modal.change("props", subtitle);
                handleClose();
              }}
            >
              <ListItemIcon>
                <StyleOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText>Змяніць стылі</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                modal.change("edit.actor", true);
                modal.change("props", subtitle);
                handleClose();
              }}
            >
              <ListItemIcon>
                <PersonOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText>Змяніць акцёра</ListItemText>
            </MenuItem>
          </div>
        )}
        <Divider sx={{ my: 1 }} />
        <MenuItem>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Скапіяваць {multipleSelection ? "радкі" : "радок"}
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Выразаць {multipleSelection ? "радкі" : "радок"}
          </ListItemText>
        </MenuItem>
        {!multipleSelection && (
          <div>
            <MenuItem>
              <ListItemIcon>
                <ContentCopy fontSize="small" />
              </ListItemIcon>
              <ListItemText>Скапіяваць таймінг</ListItemText>
            </MenuItem>
            <Divider sx={{ my: 1 }} />
            <MenuItem
              onClick={() => {
                modal.change("edit.comment", true);
                modal.change("props", subtitle);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AssignmentOutlined fontSize="small" />
              </ListItemIcon>

              <ListItemText>
                {subtitle?.Comment
                  ? "Рэдагаваць каментарый"
                  : "Дадаць каментарый"}
              </ListItemText>
            </MenuItem>{" "}
          </div>
        )}
        <Divider sx={{ my: 1 }} />
        <MenuItem
          onClick={() => {
            handleClose();

            if (subtitle?.id) {
              vivysub.removeEvent(subtitle.id);
            }
          }}
        >
          <ListItemIcon>
            <DeleteOutlined fontSize="small" sx={{ color: "error.light" }} />
          </ListItemIcon>
          <ListItemText sx={{ color: "error.light" }}>
            Выдаліць {multipleSelection ? `${selectedItems} радкоў` : ""}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default React.memo(SubMenu);
