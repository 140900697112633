import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  CircularProgress,
  DialogActions,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useModal } from "../../../core/store/modal";
import {
  Edit,
  InsertDriveFileOutlined,
  Close,
  WhatshotOutlined,
  ArrowBack,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";

import { useVivysub } from "../../../core/helpers/vivysub";
import { useVideo } from "../../../core/store/video";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../../core/hooks/useWindowSize";

function RenderDialog() {
  const { t } = useTranslation();
  const [working, setWorking] = useState(false);
  const [state, setState] = useState<any>({});
  const { enqueueSnackbar } = useSnackbar();
  const modal = useModal();
  const { width } = useWindowSize();
  const theme = useTheme();
  const isMobile = width < theme.breakpoints.values.md;
  const video = useVideo();
  const { vivysub } = useVivysub();

  const [fileName, setFileName] = useState(
    video?.meta?.filePath?.replace("file://", "")
  );

  const handleClose = () => {
    modal.change("render", false);
  };

  const handleExport = async () => {
    if (!video?.meta?.filePath) {
      return;
    }

    const options = {
      title: t("modals:export.dialogs.save_as"),
      defaultPath: video?.meta?.filePath?.replace("file://", ""),
      buttonLabel: t("ui:common.save"),

      filters: [{ name: "video", extensions: [".mp4", ".mkv"] }],
    };

    const { filePath: videoPath } = await window.electron.openDialog(
      "showSaveDialog",
      options
    );

    if (!videoPath) {
      return;
    }

    const result = vivysub.export();

    const [path, clearTempFile] = window.electron.tempy.writeSync(result, {
      extension: "ass",
    });

    const subPath = path;

    if (!subPath) {
      return clearTempFile();
    }

    setWorking(true);

    window.electron.ipcRenderer.send("render-video", {
      input: video?.meta?.filePath?.replace("file://", ""),
      output: videoPath,
      subtitle: subPath,
    });

    window.electron.ipcRenderer.receive("video-render-error", console.log);
    window.electron.ipcRenderer.receive(
      "video-render-progress",
      ([{ percentComplete, avgFps, eta }]: any) => {
        setState({
          percentComplete,
          avgFps,
          eta,
          output: videoPath,
        });
      }
    );

    window.electron.ipcRenderer.receive("video-render-cancelled", () => {
      clearTempFile();
      setWorking(false);
      setState({});
      enqueueSnackbar("Рэндар скасаваны", { variant: "error" });
    });

    window.electron.ipcRenderer.receive("video-render-completed", () => {
      clearTempFile();
      setWorking(false);
      setState({});
      enqueueSnackbar("Рэндар завершаны", { variant: "success" });
    });
  };

  return (
    <div>
      <Dialog
        open={modal["render"]}
        onClose={handleClose}
        fullScreen={isMobile}
      >
        <DialogTitle
          sx={{ mt: window.electron.isMac && isMobile ? 2 : undefined }}
        >
          {isMobile && (
            <IconButton onClick={handleClose} sx={{ mr: 1, ml: -1 }}>
              <ArrowBack />
            </IconButton>
          )}
          Рэндар відэа
        </DialogTitle>
        <DialogContent>
          {working ? (
            <Box sx={{ minWidth: 360 }}>
              {!state?.eta ? (
                <Box
                  sx={{
                    display: "flex",
                    height: 260,
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <CircularProgress />
                  <Typography sx={{ mt: 1 }}>Пачынаецца рэндар...</Typography>
                </Box>
              ) : (
                <>
                  <LinearProgress
                    variant="determinate"
                    value={state?.percentComplete || 0}
                    sx={{ mb: 2 }}
                  />
                  <ListItemText
                    primary={"Шлях да файла"}
                    secondary={state?.output}
                  />
                  <ListItemText
                    primary={"Прыблізны час рэндару"}
                    secondary={state?.eta}
                  />
                  <ListItemText
                    primary={"Сярэдні фпс"}
                    secondary={state?.avgFps}
                  />
                </>
              )}
            </Box>
          ) : (
            <Box>
              <TextField
                select
                value="1"
                label="Прэсет"
                fullWidth
                variant="standard"
              >
                <MenuItem value="1">Лепшая якасць</MenuItem>
                <MenuItem value="2">Невялікі памер файла</MenuItem>
              </TextField>
              <List sx={{ p: 0, mx: -2, mt: 2, minWidth: 360 }}>
                <ListItem>
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <InsertDriveFileOutlined />
                  </ListItemIcon>

                  <ListItemText
                    primary={"Відэа для рэндара"}
                    secondary={
                      fileName ||
                      video?.meta?.filePath?.replace("file://", "") ||
                      "Файл не выбраны"
                    }
                    secondaryTypographyProps={{
                      sx: {
                        maxWidth: 380,
                      },
                    }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={async () => {
                        const { filePaths, canceled } =
                          await window.electron.openDialog("showOpenDialog", {
                            properties: ["openFile", "noResolveAliases"],
                            filters: [
                              {
                                name: "Video",
                                extensions: ["mp4", "mkv"],
                              },
                            ],
                          });

                        if (canceled) {
                          return;
                        }

                        const [path] = filePaths;

                        setFileName(path);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Box>
          )}
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          {working ? (
            <Button
              color="error"
              variant="contained"
              startIcon={<Close />}
              onClick={() => {
                window.electron.ipcRenderer.send("render-cancel");
              }}
              fullWidth={isMobile}
            >
              Спыніць
            </Button>
          ) : (
            <Button
              variant={"gradient" as any}
              startIcon={<WhatshotOutlined />}
              onClick={handleExport}
              disabled={!fileName}
              fullWidth={isMobile}
            >
              Пачаць
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(RenderDialog);
