import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useModal } from "../../../core/store/modal";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

function SearchDialog() {
  const modal = useModal();
  const { t } = useTranslation();
  const handleClose = () => {
    modal.change("search", false);
  };

  return (
    <div>
      <Dialog open={modal["search"]} onClose={handleClose}>
        <DialogContent sx={{ width: 460, p: 0 }}>
          <TextField fullWidth placeholder={t("modals:search.placeholder")} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default React.memo(SearchDialog);
