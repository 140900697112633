import { createSelectorHook } from "../../helpers/createSelectorHook";
import { RootState } from "../rootReducer";

const getSubtitles = createSelectorHook(
  () => (state: RootState) => state.subtitles
);

const getDialogs = createSelectorHook<RootState["subtitles"]["dialogs"]>(
  () => (state: RootState) => state.subtitles.dialogs
);

const getSelection = createSelectorHook<RootState["subtitles"]["selected"]>(
  () => (state: RootState) => state.subtitles.selected
);

const getStyles = createSelectorHook<RootState["subtitles"]["styles"]>(
  () => (state: RootState) => state.subtitles.styles
);

const getActors = createSelectorHook<RootState["subtitles"]["actors"]>(
  () => (state: RootState) => state.subtitles.actors
);

const getFonts = createSelectorHook<RootState["subtitles"]["fonts"]>(
  () => (state: RootState) => state.subtitles.fonts
);

const getInfo = createSelectorHook<RootState["subtitles"]["info"]>(
  () => (state: RootState) => state.subtitles.info
);

const getOriginal = createSelectorHook<RootState["subtitles"]["original"]>(
  () => (state: RootState) => state.subtitles.original
);

const getTranslated = createSelectorHook<RootState["subtitles"]["translated"]>(
  () => (state: RootState) => state.subtitles.translated
);

const getHistory = createSelectorHook<RootState["subtitles"]["history"]>(
  () => (state: RootState) => state.subtitles.history
);

const getCurrentSubtitle = createSelectorHook<
  RootState["subtitles"]["currentSubtitle"]
>(() => (state: RootState) => state.subtitles.currentSubtitle);

const getMeta = createSelectorHook<RootState["subtitles"]["meta"]>(
  () => (state: RootState) => state.subtitles.meta
);

const getFilters = createSelectorHook<RootState["subtitles"]["filters"]>(
  () => (state: RootState) => state.subtitles.filters
);

export const subtitlesSelectors = {
  getFilters,
  getActors,
  getCurrentSubtitle,
  getDialogs,
  getFonts,
  getHistory,
  getInfo,
  getMeta,
  getOriginal,
  getSelection,
  getStyles,
  getSubtitles,
  getTranslated,
};
