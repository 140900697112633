import { AccountCircleOutlined, Cloud, Folder } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import HistoryIcon from "@mui/icons-material/History";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getDataString } from "../../../core/helpers/utils";
import { hasElectron } from "../../../core/helpers/polyfill";
import { useModal } from "../../../core/store/modal";

const menuOptions = [
  {
    id: 0,
    icon: HistoryIcon,
    label: "Апошнія файлы",
  },
  {
    id: 1,
    icon: Folder,
    label: "Праекты",
  },
  {
    id: 2,
    icon: Cloud,
    label: "Воблака",
  },
];

export const UserMenu = ({
  onChange,
  currentTab,
}: {
  onChange?: (value: number) => void;
  currentTab?: number;
}) => {
  const [selected, setSelectedHandler] = useState(currentTab || 0);
  const [open, setOpen] = useState(true);
  const authorised = false;
  const { change } = useModal();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const setSelected = (value: number) => {
    setSelectedHandler(value);
    if (onChange) {
      onChange(value);
    }
  };

  const handleOpenAuthorization = () => {
    change("authorization", true);
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={open}
      PaperProps={{
        sx: {
          position: "relative",
          paddingTop: 1,
          height: hasElectron ? "calc(100vh - 79px)" : "calc(100vh - 53px)",
          width: open ? 290 : 70,
        },
      }}
    >
      <MenuItem
        sx={{
          margin: open ? "2px 9px" : "2px auto",
          borderRadius: open ? "12px" : "50%",
          padding: open ? "3px 4.5px" : "0px 6px",
          height: open ? undefined : 50,
          width: open ? undefined : 50,
          alignItems: "center",
          justifyContent: "center",
          cursor: "default",
        }}
        disableRipple
        disableTouchRipple
        focusRipple={false}
        style={{ backgroundColor: "transparent" }}
      >
        {authorised && (
          <>
            <IconButton sx={{ p: 0.5, m: -0.5 }}>
              <Avatar alt="Vivy Sub" src="/broken-image.jpg" />
            </IconButton>
            {open && (
              <Box sx={{ ml: 2, flex: 1, mr: 2 }}>
                <ListItemText
                  primary="account@vivysub.app"
                  primaryTypographyProps={{
                    sx: {
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    },
                  }}
                  secondary={<Box>{getDataString(["0MB of 200MB"])}</Box>}
                />
              </Box>
            )}
          </>
        )}
        {!authorised && (
          <>
            {open ? (
              <Button
                fullWidth
                size="large"
                sx={{
                  justifyContent: "flex-start",
                  p: 0,
                  ml: -0.5,
                }}
                onClick={handleOpenAuthorization}
              >
                <IconButton size="small">
                  <AccountCircleOutlined fontSize="large" color="primary" />
                </IconButton>
                <Typography sx={{ ml: 1 }}>Увайсці</Typography>
              </Button>
            ) : (
              <IconButton size="small" onClick={handleOpenAuthorization}>
                <AccountCircleOutlined fontSize="large" />
              </IconButton>
            )}
          </>
        )}
      </MenuItem>
      <Box sx={{ my: 2 }} />
      {menuOptions.map(({ icon: Icon, label, id }) => {
        const isSelected = selected === id;
        return (
          <MenuItem
            onClick={() => setSelected(id)}
            selected={isSelected}
            sx={{
              margin: "8px",
              borderRadius: "12px",
              padding: "0px 6px",
              height: 50,
            }}
          >
            <ListItemIcon sx={{ justifyContent: "center", ml: "2px" }}>
              <Icon color={isSelected ? "primary" : undefined} />
            </ListItemIcon>
            {open && <ListItemText sx={{ ml: 1 }}>{label}</ListItemText>}
          </MenuItem>
        );
      })}

      <Box sx={{ flex: 1 }} />
      <MenuItem
        onClick={toggleDrawer}
        sx={{
          margin: "8px",
          borderRadius: "12px",
          padding: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton disableRipple>
          {!open ? (
            <ChevronRightIcon fontSize="large" />
          ) : (
            <ChevronLeftIcon fontSize="large" />
          )}
        </IconButton>
        {open && <ListItemText primary="Згарнуць меню" />}
      </MenuItem>
    </Drawer>
  );
};
