import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { Button, DialogActions, Divider, TextField } from "@mui/material";

import { useModal } from "../../../core/store/modal";

import { Box } from "@mui/system";
import { translateSubtitlesKey } from "../../../core/helpers/utils";
import { useSubtitles } from "../../../core/store/subtitles";
import { subtitlesSelectors } from "../../../core/store/subtitles/selectors";
import { useTranslation } from "react-i18next";

function EditCommentDialog() {
  const modal = useModal();
  const { t } = useTranslation();
  const { change } = useSubtitles();
  const [value, setValue] = useState(modal?.props?.Comment);
  const translated = subtitlesSelectors.getTranslated();
  const handleClose = () => {
    modal.change("edit.comment", false);
    modal.change("props", {});
  };

  const handleSave = () => {
    const id = modal?.props?.id;

    if (id) {
      const newTranslated = translateSubtitlesKey(
        id,
        "Comment",
        value || "",
        translated
      );

      change("translated", newTranslated);

      handleClose();
    }
  };

  return (
    <div>
      <Dialog open={modal?.edit?.comment} onClose={handleClose}>
        <DialogTitle>{t("modals:edit_comment.title")}</DialogTitle>
        <DialogContent sx={{ minWidth: 460 }}>
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              value={value}
              onChange={({ target: { value } }) => setValue(value)}
              multiline
              minRows={5}
              placeholder={t("modals:edit_comment.placeholder")}
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose}>{t("ui:common.cancel")}</Button>
          <Button variant={"gradient" as any} onClick={handleSave}>
            {t("ui:common.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditCommentDialog;
