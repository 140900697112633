import React, { useEffect } from "react";
import { ThemeProvider, createTheme, ThemeOptions } from "@mui/material/styles";
import { darkTheme, lightTheme } from "./core/theme";
import CssBaseline from "@mui/material/CssBaseline";
import StartPage from "./app/pages/Start";
import MainPage from "./app/pages/Main/component";
import { Routes, Route, useLocation } from "react-router-dom";
import EditorPage from "./app/pages/Editor/component";
import Menubar from "./app/components/Menubar";
import { Box, Container } from "@mui/material";
import Modals from "./app/components/modals";
import { useSettings } from "./core/store/settings";
import PIP from "./app/components/editor/Pip";
import Backdrop from "./app/components/Backdrop";
import { hasElectron } from "./core/helpers/polyfill";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Device } from "@capacitor/device";
import { Filesystem } from "@capacitor/filesystem";

StatusBar.setOverlaysWebView({ overlay: true });

const Application = () => {
  const { ui } = useSettings();
  const location = useLocation();
  const isPip = location.pathname === "/pip";

  useEffect(() => {
    Device.getInfo().then((status) => {
      if (["android", "ios"].includes(status.operatingSystem)) {
        StatusBar.show();
        Filesystem.requestPermissions();
      }
    });
  }, []);

  const theme = React.useMemo(() => {
    const th = ui.mode === "light" ? lightTheme : darkTheme;

    Device.getInfo().then((status) => {
      if (["android", "ios"].includes(status.operatingSystem)) {
        StatusBar.setStyle({
          style: ui.mode === "light" ? Style.Light : Style.Dark,
        });
      }
    });

    return createTheme(th as any as ThemeOptions);
  }, [ui.mode]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          background: (theme) => theme.palette.background.default,
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <CssBaseline />
        {ui.firstLogin ? (
          <StartPage />
        ) : (
          <>
            {!isPip && <Menubar />}
            <Container
              maxWidth={false}
              disableGutters
              sx={{
                mt: hasElectron
                  ? "79px"
                  : "calc(env(safe-area-inset-top) + 53px)",
                background: (theme) => theme.palette.background.default,
                overflow: "hidden",
                maxHeight: hasElectron
                  ? "calc(100vh - 79px)"
                  : "calc(100vh - calc(env(safe-area-inset-bottom) + env(safe-area-inset-top) + 53px))",
              }}
            >
              <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/edit" element={<EditorPage />} />
                <Route path="/pip" element={<PIP />} />
              </Routes>
            </Container>
            <Backdrop />
          </>
        )}

        <Modals />
      </Box>
    </ThemeProvider>
  );
};

export default Application;
