import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useModal } from "../../../core/store/modal";
import { useTranslation } from "react-i18next";
import { useDevice } from "../../../core/hooks/useDevice";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

function ViewDialog() {
  const { t } = useTranslation();
  const modal = useModal();
  const { isMobile } = useDevice();

  const handleClose = () => {
    modal.change("about", false);
  };

  return (
    <div>
      <Dialog open={modal["about"]} onClose={handleClose} fullScreen={isMobile}>
        <DialogTitle
          sx={{ mt: window.electron.isMac && isMobile ? 2 : undefined }}
        >
          {isMobile && (
            <IconButton onClick={handleClose} sx={{ mr: 1, ml: -1 }}>
              <ArrowBack />
            </IconButton>
          )}
          {t("modals:about.title")}
        </DialogTitle>
        <DialogContent>{t("modals:about.description")}</DialogContent>
      </Dialog>
    </div>
  );
}

export default React.memo(ViewDialog);
