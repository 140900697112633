import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useModal } from "../../../core/store/modal";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  TextField,
  useTheme,
} from "@mui/material";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined";
import KeyboardAltOutlinedIcon from "@mui/icons-material/KeyboardAltOutlined";
import { IOSSwitch } from "../IOSSwitch";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../../core/hooks/useWindowSize";
import { ArrowBack } from "@mui/icons-material";

const UI = () => {
  const { i18n } = useTranslation();

  return (
    <Box sx={{ mt: 1 }}>
      <TextField
        select
        fullWidth
        label="Мова"
        value={i18n.language}
        onChange={(e) => {
          i18n.changeLanguage(e.target.value);
        }}
        variant="standard"
      >
        <MenuItem value="be">Беларуская мова</MenuItem>
        <MenuItem value="en">English</MenuItem>
      </TextField>
      <TextField
        sx={{ mt: 2 }}
        select
        fullWidth
        value="be"
        label="Шрыфт"
        disabled
        variant="standard"
      >
        <MenuItem value="be">Montserrat</MenuItem>
      </TextField>
    </Box>
  );
};

const SyncSection = () => {
  return (
    <Box>
      <TextField
        sx={{ mt: 1 }}
        fullWidth
        label="Папка для файлаў аўтазахавання"
        variant="standard"
        value={"/Documents/vivysub"}
      />
      <FormControlLabel
        control={<IOSSwitch />}
        label="Аўтазахаванне"
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

const Shortcuts = () => {
  return <Box>Гэты раздзел у распрацоўкі.</Box>;
};

const sections: any = {
  ui: <UI />,
  sync: <SyncSection />,
  shortcuts: <Shortcuts />,
};

function SettingsDialog() {
  const theme = useTheme();
  const { t } = useTranslation();
  const modal = useModal();
  const [value, setValue] = React.useState<any>("ui");
  const { width } = useWindowSize();
  const handleClose = () => {
    modal.change("settings", false);
  };

  const isMobile = width < theme.breakpoints.values.md;

  return (
    <div>
      <Dialog
        open={modal["settings"]}
        onClose={handleClose}
        fullScreen={isMobile}
      >
        <DialogTitle
          sx={{
            alignItems: "center",
            display: "flex",
            mt: window.electron.isMac && isMobile ? 2 : undefined,
          }}
        >
          {isMobile && (
            <IconButton onClick={handleClose} sx={{ mr: 1, ml: -1 }}>
              <ArrowBack />
            </IconButton>
          )}
          {t("modals:settings.title")}
        </DialogTitle>
        <DialogContent sx={{ width: 500, maxWidth: "100vw" }}>
          {sections[value] || ""}
        </DialogContent>

        <Divider />
        <DialogActions sx={{ p: 0 }}>
          <BottomNavigation
            showLabels
            sx={{
              width: "100%",
              maxWidth: "100vw",
              background: "transparent",
              height: 68,
            }}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction
              label={t("modals:settings.menu.ui")}
              value="ui"
              icon={<GridViewOutlinedIcon />}
            />
            <BottomNavigationAction
              label={t("modals:settings.menu.sync")}
              value="sync"
              icon={<CloudSyncOutlinedIcon />}
            />

            <BottomNavigationAction
              label={t("modals:settings.menu.shortcuts")}
              value="shortcuts"
              sx={{ whiteSpace: "nowrap" }}
              icon={<KeyboardAltOutlinedIcon />}
            />
          </BottomNavigation>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(SettingsDialog);
