import { CircularProgress, Backdrop as BackDrop } from "@mui/material";
import React from "react";
import { useSettings } from "../../core/store/settings";

const Backdrop = () => {
  const { ui, change } = useSettings();

  return (
    <BackDrop
      sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
      open={ui.backdrop}
      onClick={() => {
        change("ui.backdrop", false);
      }}
    >
      <CircularProgress color="inherit" />
    </BackDrop>
  );
};

export default Backdrop;
