import React, { useMemo } from "react";

export const withWrapper = (
  Component: any,
  useWrapper: any,
  options: any = {}
) => {
  const Wrapper = (customProps = {}) => {
    const props = useWrapper(options, customProps);

    const nextProps = useMemo(() => {
      return {
        ...(props || {}),
        ...customProps,
      };
    }, [props, customProps]);

    return <Component {...nextProps} />;
  };

  return React.memo(Wrapper);
};
