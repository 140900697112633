/* eslint-disable react-hooks/exhaustive-deps */
/* tslint:disable */
import React, { useEffect, useMemo, useRef, useState } from "react";
import SubtitlesOctopus from "libass-wasm";
import assStringify from "ass-stringify";
import fonts from "google-fonts-complete";
import { Box, IconButton, useTheme } from "@mui/material";
import { compareSubtitles, timeToSeconds } from "../../../core/helpers/utils";
import { useVideo } from "../../../core/store/video";
import { isEmpty } from "lodash";
import { useHover } from "../../../core/hooks/useHover";
import { Close, PauseOutlined, PlayArrowOutlined } from "@mui/icons-material";
import { subtitlesSelectors } from "../../../core/store/subtitles/selectors";

let instences: typeof SubtitlesOctopus[] = [];

const PIP = () => {
  const theme = useTheme();
  const [hoverRef, isHovered] = useHover();
  const [state, setState] = useState<any>({});
  const { meta } = useVideo();

  const uniqFonts = subtitlesSelectors.getFonts();
  const translated = subtitlesSelectors.getTranslated();
  const styles = subtitlesSelectors.getStyles();
  const currentSubtitle = subtitlesSelectors.getCurrentSubtitle();
  const original = subtitlesSelectors.getOriginal();

  const fontsSrc = useMemo(() => {
    const result = [
      "http://link.eu1.storjshare.io/jwtavenjehavhz34o55fatu4bpyq/anibel%2FFonts%2FMontserrat-Bold.ttf?download",
    ];

    for (const { Fontname } of uniqFonts) {
      const [name] =
        Fontname?.split("Bold")?.map((d: string) => d.trim()) || [];
      const font = fonts[name];

      if (font) {
        for (const variant of Object.entries(font.variants)) {
          const value = variant[1];

          for (const details of Object.entries(value as any)) {
            const {
              url: { ttf },
            } = details[1] as any;

            result.push(ttf.replace("https", "http"));
          }
        }
      }
    }

    return result;
  }, [styles]);

  const videoRef = useRef<HTMLVideoElement>({} as HTMLVideoElement);

  const octopusInstance = useMemo(() => {
    if (!meta.filePath || isEmpty(videoRef.current) || !translated?.length) {
      return null;
    }
    let subContent = "";
    try {
      subContent = assStringify(translated);
    } catch (error) {
      console.log(error);
    }

    const instance = new SubtitlesOctopus({
      fonts: fontsSrc,
      subContent,
      video: videoRef.current,
      lossyRender: true,
    });

    for (const iterator of instences) {
      try {
        iterator?.dispose();
      } catch (error) {}
    }

    instences = [];
    instences.push(instance);

    return instance;
  }, [fontsSrc, meta.filePath, videoRef.current]);

  useEffect(() => {
    let subContent = "";
    try {
      subContent = assStringify(compareSubtitles(translated, original));
    } catch (error) {
      console.log(error);
    }

    octopusInstance?.setTrack(subContent);
  }, [translated, octopusInstance, original]);

  useEffect(() => {
    if (videoRef.current && currentSubtitle) {
      const seconds = timeToSeconds(currentSubtitle.Start);
      videoRef.current.currentTime = Number(seconds);

      if (octopusInstance) {
        octopusInstance.setCurrentTime(Number(seconds));
      }
    }
  }, [currentSubtitle, octopusInstance, videoRef.current]);

  if (!meta.filePath) {
    return null;
  }

  return (
    <div
      ref={hoverRef}
      style={{
        position: "relative",
        width: "100vw",
        height: "100vh",
      }}
    >
      {isHovered && (
        <Box
          sx={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
          }}
        >
          <IconButton
            className="no-drag"
            color="primary"
            sx={{ position: "absolute", right: 20, top: 20 }}
          >
            <Close />
          </IconButton>
          <IconButton
            size="large"
            sx={{ color: "primary.main", zIndex: 5000 }}
            onClick={() => {
              const nextValue = !state?.play;
              setState({
                ...state,
                play: nextValue,
              });

              if (nextValue) {
                videoRef.current.play();
              } else {
                videoRef.current.pause();
              }
            }}
          >
            {state.play ? <PauseOutlined /> : <PlayArrowOutlined />}
          </IconButton>
        </Box>
      )}
      <Box
        sx={
          {
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid " + theme.palette.divider,
            userSelect: "none",
            width: "100vw",
            height: "100vh",
            backgroundColor: theme.palette.background.default,
            background: "#000",
          } as any
        }
      >
        <Box
          sx={{
            textAlign: "center",
            display: "block",

            opacity: isHovered && 0.6,
          }}
        >
          <video
            ref={videoRef}
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <source src={"file://" + meta.filePath} type="video/mp4" />
          </video>
        </Box>
      </Box>
    </div>
  );
};

export default PIP;
