/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import { useModal } from "../../../core/store/modal";
import { useTranslation } from "react-i18next";
import { Button, DialogActions, DialogContent, Divider } from "@mui/material";

function ConfirmDialog() {
  const modal = useModal();
  const { onConfirm, text } = modal?.props || {};
  const { t } = useTranslation();

  const handleClose = () => {
    modal.change("confirm", false);
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }

    handleClose();
  };

  return (
    <div>
      <Dialog open={modal["confirm"]} onClose={handleClose}>
        <DialogTitle>{t("modals:confirm.title")}</DialogTitle>
        <DialogContent sx={{ minWidth: 380 }}>{text}</DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose}>{t("ui:common.no")}</Button>
          <Button variant={"gradient" as any} onClick={handleConfirm}>
            {t("ui:common.yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(ConfirmDialog);
