import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import { IModalReducer, modalReducer } from "./modal";
import { ISettingsReducer, settingsReducer } from "./settings";
import { ISubtitlesReducer, subtitlesReducer } from "./subtitles";
import { IVideoReducer, videoReducer } from "./video";
import { accountReducer } from "./account";

const root = {
  modal: modalReducer,
  settings: settingsReducer,
  subtitles: subtitlesReducer,
  video: videoReducer,
  account: accountReducer,
};

export type RootState = {
  modal: IModalReducer;
  settings: ISettingsReducer;
  subtitles: ISubtitlesReducer;
  video: IVideoReducer;
  account: any;
};

export const rootReducer = combineReducers(root);

export function* rootSaga() {
  yield all([]);
}
